var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.meta.hideHeader),expression:"!$route.meta.hideHeader"}],class:[
    'header',
    _vm.menuIsOpen ? 'header--menu' : false,
    _vm.overlay && _vm.overlayHeader ? 'header--overlay' : false ],style:({ zIndex: _vm.navigationBar ? 1 : 3 })},[_c('div',{directives:[{name:"visible",rawName:"v-visible",value:(!_vm.navigationBar),expression:"!navigationBar"}],class:['top-bar', _vm.overlay && _vm.overlayHeader ? false : 'invert']},[_c('router-link',{staticClass:"logo",attrs:{"to":_vm.lang === _vm.defaultLang ? '/' : ("/" + _vm.lang + "/")}},[_c('inline-svg',{attrs:{"src":require(("@/assets/svg/" + (_vm.$mq.isMobile ? 'logo-icon' : 'logo') + ".svg"))}})],1),(_vm.overlay)?_c('Icon',{attrs:{"name":"close","fn":_vm.closeOverlay}}):_c('div',{staticClass:"right-side"},[_c('div',{staticClass:"highlight"},_vm._l((_vm.highlight),function(item){return _c('Link',{key:item.id,attrs:{"data":{
            url: item.url,
            title: item.content,
            target: item.target,
          },"theme":"link"}})}),1),_c('Link',{attrs:{"data":{
          fn: _vm.toggleMenu,
          title: _vm.menuIsOpen ? _vm.$labels().close_cta : _vm.$labels().menu_cta,
        },"theme":"link"}})],1)],1),(_vm.navigationBar)?_c('NavigationBar'):_vm._e(),(_vm.navigationBar && _vm.$route.meta.typeName === 'event')?_c('DatePicker',{attrs:{"theme":"desktop"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }