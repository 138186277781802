<template>
  <div :class="['archive-view', pinned ? 'archive-view--pinned' : false]">
    <Archive
      :pinned="pinned"
      :data="archive"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import data from '@/mixins/data';
import Archive from '@/components/blocks/archive';

export default {
  name: 'ArchiveView',
  components: {
    Archive,
  },
  mixins: [data],
  data() {
    return {
      archive: {
        value: {
          post_type: this.$route.meta.typeName,
        },
        settings: {
          infinite_scroll: true,
          posts_per_page: 12,
          filters: [
            this.$route.meta.agenda ? `agenda:${this.$route.meta.agenda}` : '',
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters(['pinnedExhibition']),
    pinned() {
      if (this.$route.meta.typeName === 'exhibition') {
        if (this.$route.meta.agenda === 'current') {
          return this.pinnedExhibition;
        }
        if (
          this.$route.meta.single
          && this.$store.state.route.from.meta.agenda === 'current'
        ) {
          return this.pinnedExhibition;
        }
      }
      return false;
    },
  },
  mounted() {
    if (!this.pinnedExhibition) {
      this.getPinned();
    }
  },
  methods: {
    ...mapMutations(['SET_PINNED_EXHIBITION']),
    getPinned() {
      let value = null;

      if (this.post && this.$route.meta.typeName === 'exhibition') {
        value = this.post.acf.pinned_exhibition;
      }

      if (value) {
        const today = new Date();
        const start = new Date(value.acf.duration.value.start.date);
        const end = new Date(value.acf.duration.value.end.date);

        if (start) {
          if (start > today) {
            value = null;
          }
        }

        if (end) {
          if (end < today) {
            value = null;
          }
        }
      }

      this.SET_PINNED_EXHIBITION(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.archive-view {
  padding: 0 var(--spacer-s);
  padding-top: 116px;

  @include mq(m) {
    padding-top: var(--spacer-xxl);
  }

  &--pinned {
    padding-top: 0;
  }
}
</style>
