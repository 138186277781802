var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",class:[
    _vm.template ].concat( _vm.gridCol,
    _vm.gridJustify,
    _vm.gridAlign,
    _vm.extraClass,
    [_vm.rowGap && _vm.rowGap !== '' ? ("row-gap-" + _vm.rowGap) : false],
    [_vm.columnGap && _vm.columnGap !== '' ? ("column-gap-" + _vm.columnGap) : false],
    [!_vm.grid ? 'grid--default' : false] ),style:(_vm.grid ? { '--columns' : _vm.grid } : undefined)},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }