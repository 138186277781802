<template>
  <div class="duration typo--p-small">
    <div
      v-if="date.start || date.end"
      class="t-ellipsis"
    >
      {{ date.start ? date.start : null }}
      {{ date.end && date.start !== date.end ? `– ${date.end}` : null }}
    </div>
    <div
      v-if="showTime && (time.start || time.end)"
      class="t-ellipsis"
    >
      {{ time.start ? time.start : null }}
      {{ time.end ? `– ${time.end}` : null }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    duration: {
      type: Object,
      default: () => {},
    },
    format: {
      type: String,
      default: 'short',
    },
    showTime: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['lang']),
    date() {
      const value = {
        start: false,
        end: false,
      };

      // function formatDate(date) {
      //   return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;
      // }

      function formatDate(date, extreme, locale, format) {
        if (date !== '') {
          let options = false;
          const formattingLocale = locale === 'it' ? 'it-IT' : 'en-GB';

          if (locale === 'it') {
            if (format === 'full') {
              options = {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              };
            }
            if (format === 'short') {
              options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              };
            }
          } else if (locale === 'en') {
            options = {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            };
          }
          value[extreme] = new Date(date)
            .toLocaleString(formattingLocale, options)
            .replaceAll('/', '.');
        }
      }

      const { duration } = this;
      formatDate(duration.start.date, 'start', this.lang, this.format);
      formatDate(duration.end.date, 'end', this.lang, this.format);

      return value;
    },
    time() {
      const { start } = this.duration;
      const { end } = this.duration;

      return {
        start: start.time !== '' ? start.time : false,
        end: end.time !== '' ? end.time : false,
      };
    },
  },
};
</script>

<style></style>
