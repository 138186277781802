<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :class="[
      'icon',
      `icon--${theme}`,
      `icon--${name}`,
      disabled ? 'icon--disabled' : false,
    ]"
    :to="to ? (typeof to === 'string' ? $relativeUrl(to) : to) : undefined"
    @click.stop="fn"
  >
    <inline-svg :src="require(`@/assets/svg/icons/${name}.svg`)" />
  </component>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    fn: {
      type: Function,
      default: () => {},
    },
    to: {
      type: String,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
  .icon {
    display: grid;
    --icon-size: 21px;

    svg {
      height: var(--icon-size);
      width: var(--icon-size);
    }

    &.white {
      svg {
        fill: #fff;
      }
    }

    &--l {
      --icon-size: 36px;
    }

    &--max {
      --icon-size: 100%;
    }

    &--disabled {
      opacity: 0.3 !important;
      pointer-events: none !important;
    }
  }
</style>
