var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.mergeProps.link ? (_vm.externalUrl ? 'a' : 'RouterLink') : 'div',{tag:"component",class:[
    'heading',
    ("heading--" + (_vm.mergeProps.link ? 'link' : _vm.mergeProps.typo)),
    ("heading--" + (_vm.mergeProps.typo) + (_vm.mergeProps.theme ? ("--" + (_vm.mergeProps.theme)) : '')) ],attrs:{"href":_vm.externalUrl ? _vm.mergeProps.link.url : undefined,"to":!_vm.externalUrl ? _vm.mergeProps.link.url : undefined,"target":_vm.externalUrl ? '_blank' : '_self'}},[_c(_vm.mergeProps.tag,{tag:"component",class:['title', ("typo--" + (_vm.mergeProps.typo) + (_vm.trim ? '--trimmed' : ''))],domProps:{"innerHTML":_vm._s(_vm.mergeProps.value)}}),(_vm.mergeProps.link)?_c('Link',{attrs:{"theme":"full","data":{
      url: _vm.mergeProps.link.url,
      title: _vm.mergeProps.link.title,
      target: _vm.mergeProps.link.target,
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }