var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'archive',
    ("archive--" + _vm.type),
    _vm.pinned ? 'archive--pinned' : false,
    _vm.items.length === 0 ? 'archive--empty' : false ]},[(_vm.data.value.heading)?_c('Heading',{attrs:{"value":_vm.data.value.heading}}):_vm._e(),_c('div',{staticClass:"thumbs"},_vm._l((_vm.filteredItems),function(item,index){return _c(_vm.thumbs.get(_vm.type),{key:item.id,tag:"component",staticClass:"item",attrs:{"data":item,"pinned":_vm.pinned && index === 0}})}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.items && _vm.data.settings.infinite_scroll),expression:"items && data.settings.infinite_scroll"}],ref:"infinite",staticClass:"infinite"}),(_vm.data.value.link)?_c('Link',{attrs:{"data":_vm.data.value.link}}):_vm._e(),(!_vm.loading && _vm.filteredItems.length === 0)?_c('Heading',{attrs:{"data":{
      value: {
        heading: _vm.$labels()[("empty_" + _vm.type + "_archive_message")],
      },
      settings: {
        typo: '4',
      },
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }