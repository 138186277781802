<template>
  <div
    :class="[
      'archive',
      `archive--${type}`,
      pinned ? 'archive--pinned' : false,
      items.length === 0 ? 'archive--empty' : false,
    ]"
  >
    <Heading
      v-if="data.value.heading"
      :value="data.value.heading"
    />
    <div class="thumbs">
      <component
        :is="thumbs.get(type)"
        v-for="(item, index) in filteredItems"
        :key="item.id"
        class="item"
        :data="item"
        :pinned="pinned && index === 0"
      />
    </div>
    <div
      v-show="items && data.settings.infinite_scroll"
      ref="infinite"
      class="infinite"
    />
    <Link
      v-if="data.value.link"
      :data="data.value.link"
    />
    <Heading
      v-if="!loading && filteredItems.length === 0"
      :data="{
        value: {
          heading: $labels()[`empty_${type}_archive_message`],
        },
        settings: {
          typo: '4',
        },
      }"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import medusa from '@/assets/js/observer';

import Thumb from '@/components/thumb';
import PageThumb from '@/components/thumb/page';
import WorkThumb from '@/components/thumb/work';
import Link from '@/components/typo/link';
import Heading from '@/components/typo/heading';

export default {
  name: 'Archive',
  components: {
    Heading,
    Link,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    pinned: {
      type: [Object, Boolean],
      default: false,
    },
  },
  data() {
    return {
      thumbs: new Map([
        ['event', Thumb],
        ['exhibition', Thumb],
        ['page', PageThumb],
        ['work', WorkThumb],
      ]),
      request: null,
      items: [],
      offset: 0,
      loading: true,
    };
  },
  computed: {
    ...mapState(['filter', 'eventsDate']),
    ...mapGetters(['postType', 'lang']),
    type() {
      return this.data.value.post_type;
    },
    filteredItems() {
      let value = this.items;
      if (this.type === 'event') {
        if (this.filter) {
          value = value.filter((item) => item.gds_taxonomies.event_category
            ?.map((category) => category.name)
            .includes(this.filter));
        }
        if (this.eventsDate) {
          value = value.filter((item) => {
            const duration = item.acf.duration.value;
            const start = new Date(duration.start.date);
            const end = new Date(duration.end.date);

            if (
              start.getTime() <= this.eventsDate.getTime()
              && this.eventsDate.getTime() <= end.getTime()
            ) {
              return true;
            }

            return false;
          });
        }
      }
      return value;
    },
  },
  watch: {
    filter() {
      this.$root.$children[0].lazyObserve(this.$el);
    },
    eventsDate() {
      this.$root.$children[0].lazyObserve(this.$el);
    },
  },
  mounted() {
    this.setRequest();

    this.loadItems().then(() => {
      if (this.data.settings.infinite_scroll) {
        this.$root.$children[0].lazyObserve(this.$el);
        this.$nextTick(this.setInfiniteScroll);
      } else {
        this.$root.$children[0].lazyObserve(this.$el);
      }

      if (this.pinned) {
        let alreadyInArchive = false;

        if (this.items.length > 0) {
          this.items.forEach((item) => {
            if (item.id === this.pinned.id) {
              alreadyInArchive = this.items.indexOf(item);
            }
          });
        }

        if (alreadyInArchive !== false) {
          this.items.unshift(this.items.splice(alreadyInArchive, 1)[0]);
        } else this.items.unshift(this.pinned);
      }
      this.loading = false;
    });
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        this.items = [...this.items, ...items];
      }

      return items;
    },
    setRequest() {
      const type = this.postType(this.data.value.post_type).rest_base;
      const per_page = parseInt(this.data.settings.posts_per_page, 10);
      const order = this.data.settings.order ? 'desc' : 'asc';
      const orderby = this.data.settings.order_by;
      const exclude = this.data.settings.exclude
        ? this.data.settings.exclude
        : null;
      const { offset } = this;

      const filters = {};

      this.data.settings.filters.forEach((filter) => {
        const string = filter.split(':');
        // eslint-disable-next-line prefer-destructuring
        filters[string[0]] = string[0] === 'agenda' ? string[1] : parseInt(string[1], 10);
      });

      this.request = {
        type,
        params: {
          ...filters,
          per_page,
          offset,
          order,
          orderby,
          exclude,
          lang: this.$store.state.lang,
        },
      };
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: 'infinite-scroll',
        threshold: 0.0,
        nodes: [],
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
      });

      medusa.ref.pushToTarget(
        'infinite-scroll',
        this.$el.querySelector('.infinite'),
      );
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      const { height, top } = this.$el.getBoundingClientRect();
      this.offset += parseInt(this.data.settings.posts_per_page, 10);
      this.setRequest();
      this.loadItems().then((newItems) => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (newItems.length === 0) {
          medusa.ref.removeTarget('infinite-scroll');
        } else if (window.pageYOffset + window.innerHeight >= height + top) {
          this.requestOffset();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.archive {
  position: relative;
  .thumbs {
    align-items: start;
    column-gap: var(--column-gap-s);
    display: grid;
    position: relative;

    @include mq(s) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include mq(m) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &--exhibition {
    .thumbs {
      @include mq(s) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }

  &--pinned {
    .thumb:first-child {
      grid-column: 1 / -1;
    }
  }
}
.infinite {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  height: 50vh;
  bottom: 0;
}
</style>
