<template>
  <div :class="['accordion', theme ? `accordion--${theme}` : false]">
    <Wrapper
      v-for="(item, index) in data.value.accordion"
      :key="index"
    >
      <Item class="item">
        <template #accordion-trigger>
          <div class="trigger">
            <Icon
              :theme="theme === 'big' && !$mq.isMobile ? 'l' : 'default'"
              name="plus"
            />
            <div
              :class="[
                theme === 'big' ? 'typo--nav-l--trimmed' : 'typo--nav--trimmed',
              ]"
            >
              {{ item.title }}
            </div>
          </div>
        </template>
        <template #accordion-content>
          <Richtext
            :value="item.text"
          />
        </template>
      </Item>
    </Wrapper>
  </div>
</template>

<script>
import Item from '@/components/ui/accordion-item.vue';
import Icon from '@/components/ui/icon.vue';
import Wrapper from '@/components/ui/accordion-wrapper.vue';
import Richtext from '@/components/typo/richtext';

export default {
  components: {
    Wrapper,
    Item,
    Icon,
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    theme() {
      let value = false;

      if (this.data.settings.style !== 'default') {
        value = this.data.settings.style;
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .accordion {
    display: grid;
    row-gap: var(--row-gap-xs);

    @include mq(s) {
      grid-column: 2 / span 6;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }

    .richtext {
      padding-top: var(--spacer-m);
      padding-bottom: var(--spacer-xl);
    }

    .trigger {
      align-items: center;
      cursor: pointer;
      display: grid;
      gap: var(--column-gap-s);
      grid-auto-flow: column;
      justify-content: start;
      grid-auto-columns: initial;

      .icon {
        pointer-events: none;
      }
    }

    &--big {
      row-gap: calc(var(--row-gap-xs) * 2);
    }
  }
</style>
