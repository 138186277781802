<template>
  <div
    :class="['posts', `posts--${postType}`]"
  >
    <component
      :is="thumbs.get(postType)"
      v-for="(item, index) in items"
      :key="index"
      class="item"
      :data="item"
    />
  </div>
</template>

<script>
import Thumb from '@/components/thumb';
import WorkThumb from '@/components/thumb/work';
import PageThumb from '@/components/thumb/page';
// import PageFull from '@/components/thumb/page-full';

export default {
  name: 'Posts',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      thumbs: new Map([
        ['event', Thumb],
        ['exhibition', Thumb],
        ['page', PageThumb],
        ['work', WorkThumb],
      ]),
      items: [],
      // ready: false,
    };
  },
  computed: {
    postType() {
      return this.data.value.posts[0].post.type;
    },
  },
  // mounted() {
  //   if (this.postType === 'page') {
  //     const { style } = this.data.settings;

  //     if (style === 'full') {
  //       this.thumbs.set('page', PageFull);
  //     } else this.thumbs.set('page', PageThumb);
  //   }
  //   this.ready = true;
  // },
  created() {
    this.items = this.data.value.posts.map((item) => item.post);
  },
};
</script>

<style lang="scss" scoped>
  .posts {
    align-items: start;
    column-gap: var(--column-gap-s);
    display: grid;

    @include mq(s) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include mq(m) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &--exhibition {
      @include mq(s) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }
</style>
