<template>
  <router-link
    :to="$relativeUrl(data.link)"
    class="thumb thumb--work"
  >
    <Figure
      theme="thumb"
      :data="{
        value: {
          src: data.gds_featured_image,
        },
        settings: {},
      }"
    />
    <div>
      <div v-if="artist">
        {{ artist }}
      </div>
      <div
        :class="[ 'title', data.acf.display_title.value.title
          ? false : 't-italic', ]"
        v-html="title"
      />
    </div>
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';

export default {
  name: 'WorkThumb',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    artist() {
      let value = false;
      const taxonomies = this.data.gds_taxonomies;

      if (taxonomies.artist && taxonomies.artist.length > 0) {
        value = taxonomies.artist[0].name;
      }

      return value;
    },
    title() {
      let value = false;
      if (this.data.acf.display_title.value.title) {
        value = this.data.acf.display_title.value.title;
        return value;
      } value = this.data.title.rendered;

      if (this.data.acf.date && this.data.acf.date.value.date) {
        // const helper = document.createElement('div');
        // helper.innerHTML = value;
        // const inside = helper.querySelector('p');
        value += `,&nbsp;<span>${this.data.acf.date.value.date}</span>`;

        // value = inside.innerHTML;
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .thumb {
    &--work {
      .title {
        display: flex;
        grid-auto-flow: column;
        justify-content: start;

        @include mq(m) {
          max-width: 380px;
        }

        /deep/ em {
          font-style: italic;
        }

        /deep/ span {
          font-style: initial;
        }
      }
    }
  }
</style>
