<template>
  <router-link
    :to="$relativeUrl(data.link)"
    :class="['thumb', 'thumb--page']"
  >
    <Figure
      theme="thumb"
      :data="{
        value: {
          src: data.gds_featured_image,
        },
        settings: {},
      }"
    />
    <div class="bottom">
      <div
        :class="['title', 'typo--nav--trimmed']"
        v-html="data.title.rendered"
      />
      <Richtext
        v-if="data.excerpt.rendered"
        class="excerpt"
        :value="data.excerpt.rendered"
      />
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

import Figure from '@/components/media/figure';
import Richtext from '@/components/typo/richtext';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    pinned: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['lang']),

    space() {
      let value = false;

      if (this.data.gds_taxonomies.space) {
        value = this.data.gds_taxonomies.space[0].name;
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .thumb {
    &--page {
      row-gap: var(--row-gap-s);

      .bottom {
        display: grid;
        row-gap: var(--row-gap-l);
      }

      .title {
        @include mq(m) {
          max-width: 380px;
        }
      }
    }
  }
</style>
