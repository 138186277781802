import Accordion from '@/components/blocks/accordion';
import Archive from '@/components/blocks/archive';
import Figure from '@/components/media/figure';
import Forms from '@/components/forms';
import Gallery from '@/components/media/gallery';
import Heading from '@/components/typo/heading';
import Link from '@/components/typo/link';
import Newsletter from '@/components/blocks/newsletter';
import Posts from '@/components/blocks/posts';
import Richtext from '@/components/typo/richtext';
import Spacer from '@/components/blocks/spacer';
import Table from '@/components/blocks/table';
import VectorMap from '@/components/blocks/map';
import VideoPlayer from '@/components/media/video';
import Columns from '@/components/blocks/columns';
import Embed from '@/components/blocks/embed.vue';
import Guide from '@/components/blocks/guide.vue';

const components = new Map([
  ['accordion', Accordion],
  ['archive', Archive],
  ['audio', Embed],
  ['columns', Columns],
  ['events', Posts],
  ['exhibitions', Posts],
  ['form', Forms],
  ['gallery', Gallery],
  ['heading', Heading],
  ['image', Figure],
  ['link', Link],
  ['map', VectorMap],
  ['newsletter', Newsletter],
  ['pages', Posts],
  ['paragraph', Richtext],
  ['spacer', Spacer],
  ['table', Table],
  ['video', VideoPlayer],
  ['works', Posts],
  ['guides', Guide],
]);

export default components;
