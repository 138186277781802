<template>
  <div
    v-if="name"
    :class="['element']"
  >
    <div
      v-for="option in options"
      :key="option"
      class="option"
    >
      <input
        :id="option"
        v-model="model"
        size="1"
        :name="option"
        type="checkbox"
        :required="required"
      >
      <label
        class="typo--7--trimmed"
        :for="option"
      >
        {{ $labels()[`form_${option}`] }}
      </label>
    </div>
    <div class="error typo--7--trimmed">
      {{ customError ? $labels()[`form_${name}_error`] : $labels().form_error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    customError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: '',
    };
  },
  watch: {
    model() {
      this.updateModel();
    },
  },
  methods: {
    updateModel() {
      this.$emit('update', this.name, this.model);
    },
  },
};
</script>

<style lang="scss" scoped>
  .element {
    display: grid;
    grid-column: 1 / -1;
    // grid-auto-flow: column;
    column-gap: var(--column-gap-s);
    justify-items: start;
    cursor: pointer;

    .option {
      align-items: center;
      display: grid;
      grid-auto-flow: column;
      // grid-row: 1;
      column-gap: calc(var(--spacer-xs) * 2);

      @media (hover: hover) {
        &:hover {
          input {
            opacity: 1;
            border-color: var(--black);
          }
        }
      }
    }

    label {
      cursor: inherit;
    }

    .error {
      grid-row: 2;
    }
  }
</style>
