<template>
  <div
    v-if="message"
    class="message typo--link"
    :style="{ transform: `translate3d(-${displacement}px, 0, 0)` }"
  >
    <span
      v-for="number in repeat"
      :key="number"
    >{{
      `${message} –&nbsp;`
    }}</span>
    <div
      ref="helper"
      class="helper"
    >
      {{ `${message} –&nbsp;` }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['options', 'fontsLoaded', 'lang']),
    message() {
      const { message, 'message-en': messageEn } = this.options;
      return this.lang === 'en' ? messageEn : message;
    },
  },
  data() {
    return {
      displacement: 0,
      step: 1,
      repeat: 24,
      width: 0,
    };
  },
  watch: {
    fontsLoaded() {
      this.start();
    },
  },
  mounted() {
    if (this.fontsLoaded) {
      this.start();
    }
  },
  methods: {
    getWidth() {
      this.width = 0;
      const { helper } = this.$refs;
      this.width = helper.clientWidth;
    },
    move() {
      if (this.displacement < this.width) {
        this.displacement += this.step;
      } else this.displacement = 0;
    },
    start() {
      this.getWidth();
      setInterval(() => {
        this.move();
      }, 20);
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  color: var(--red);
  white-space: nowrap;
  margin-top: 0.1em;
  line-height: 2;

  .helper {
    top: 0;
    position: absolute;
    visibility: hidden;
    white-space: nowrap;
  }
}
</style>
