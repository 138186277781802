import { render, staticRenderFns } from "./cover.vue?vue&type=template&id=99c4a396&scoped=true&"
import script from "./cover.vue?vue&type=script&lang=js&"
export * from "./cover.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99c4a396",
  null
  
)

export default component.exports