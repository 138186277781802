<template>
  <div class="columns">
    <div
      v-for="(column, columnIndex) in columns"
      :key="columnIndex"
    >
      <block
        :is="blocks.get(block.acf_fc_layout)"
        v-for="(block, blockIndex) in column.content"
        :key="blockIndex"
        :class="['column-block', `block--${block.acf_fc_layout}`]"
        :data="computedData(block)"
      />
    </div>
  </div>
</template>

<script>
import Figure from '@/components/media/figure';
import Heading from '@/components/typo/heading';
import Link from '@/components/typo/link';
import Richtext from '@/components/typo/richtext';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      blocks: new Map([
        ['image', Figure],
        ['heading', Heading],
        ['link', Link],
        ['text', Richtext],
      ]),
    };
  },
  computed: {
    columns() {
      return this.data.value.columns;
    },
  },
  methods: {
    computedData(block) {
      let value = block;
      const layout = block.acf_fc_layout;

      if (layout === 'image') {
        value = {
          value: {
            src: block.src,
          },
          settings: {
            style: 'thumb',
          },
        };
      }

      if (layout === 'heading') {
        value = {
          value: {
            heading: block.value,
          },
          settings: {
            typo: '4',
          },
        };
      }

      if (layout === 'link') {
        value = block.link;
      }

      if (layout === 'text') {
        value = {
          innerHTML: block.value,
        };
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .columns {
    column-gap: var(--column-gap-s);
    display: grid;
    row-gap: var(--row-gap-l);

    .column-block:not(:first-child) {
      margin-top: var(--spacer-m);
    }

    @include mq(s) {
      grid-column: 2 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / -1;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .block {
      &--link {
        max-width: max-content;
      }
    }
  }
</style>
