<template>
  <main class="not-found">
    <Heading
      class="block block--l"
      :data="{
        value: { heading: $labels().not_found_message },
        settings: {
          typo: '2',
        },
      }"
      :style="$mq.isMobile ? { paddingTop: '116px' } : false"
    />
    <Link
      class="block"
      :data="{
        url: lang === defaultLang ? '/' : `/${lang}/`,
        title: $labels().back_home_cta,
      }"
      theme="full"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import Heading from '@/components/typo/heading';
import Link from '@/components/typo/link';

export default {
  name: 'NotFound',
  components: {
    Heading,
    Link,
  },
  computed: {
    ...mapGetters(['lang', 'defaultLang']),
  },
};
</script>

<style lang="scss" scoped>
  .not-found {
    background: var(--red);
    min-height: calc(100 * var(--vh));
    padding: 0 var(--spacer-s);
  }
</style>
