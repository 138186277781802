<template>
  <div
    v-if="message || subMenu"
    :class="['navigation-bar', { 'navigation-bar--submenu': subMenu }]"
    :style="{ height: height }"
  >
    <Menu
      v-if="subMenu"
      :key="$route.path"
      :items="$route.meta.typeName === 'event' ? false : subMenu"
      :parent="$route.meta.typeName === 'event' ? currentFilter : parent"
      :expanded="height !== 0"
      @toggleBar="toggle"
    />
    <Link
      v-else-if="$route.name === 'SubSubPage' && currentPost"
      theme="full--invert"
      :data="{
        title: `${$labels().back_parent_cta} ${
          currentPost.parents[0].post_title
        }`,
        url: currentPost.parents[0].link,
      }"
    />
    <Message
      v-else
      :key="`${$mq.isMobile}${lang}`"
    />
  </div>
</template>

<script>
import Link from '@/components/typo/link';
import Message from '@/components/ui/navigation-bar/message';
import Menu from '@/components/ui/navigation-bar/menu';

import { mapGetters, mapState } from 'vuex';

export default {
  components: { Link, Message, Menu },
  data() {
    return {
      height: 0,
      subMenu: false,
      parent: '',
    };
  },
  computed: {
    ...mapGetters([
      'currentPost',
      'menu',
      'lang',
      'options',
      'eventCategories',
    ]),
    ...mapState(['filter']),
    currentFilter() {
      if (this.filter) {
        return this.filter;
      }
      return this.$labels().archive_filters_all;
    },
    message() {
      return this.options.message;
    },
  },
  watch: {
    currentPost(newValue, oldValue) {
      if (
        !oldValue
        && (newValue || typeof newValue === 'undefined')
        && !this.$route.meta.single
      ) {
        this.getSubMenu();
      }
    },
  },
  methods: {
    async getSubMenu() {
      if (this.$route.meta.agenda) {
        let subMenu = false;
        if (this.$route.meta.typeName === 'exhibition') {
          const upcoming = await this.$store.dispatch('getItems', {
            type: 'exhibitions',
            params: {
              agenda: 'upcoming',
              per_page: 1,
              lang: this.$store.state.lang,
            },
          });

          subMenu = {
            it: [
              {
                url: 'in-corso',
                content: 'In corso',
                target: null,
              },
              {
                url: 'future',
                content: 'Future',
                target: null,
                hide: !upcoming.length,
              },
              {
                url: 'passate',
                content: 'Passate',
                target: null,
              },
            ],
            en: [
              {
                url: 'current',
                content: 'Current',
                target: null,
              },
              {
                url: 'upcoming',
                content: 'Upcoming',
                target: null,
                hide: !upcoming.length,
              },
              {
                url: 'past',
                content: 'Past',
                target: null,
              },
            ],
          };
        }

        if (this.$route.meta.typeName === 'event') {
          subMenu = true;
        }

        this.subMenu = subMenu[this.lang] || subMenu;
        this.parent = this.currentPost.title.rendered;
      } else {
        let value = false;
        const items = [
          ...this.menu('navigation-1').items,
          ...this.menu('navigation-2').items,
        ];

        let link = this.currentPost;
        if (link && this.$route.name === 'SubPage') {
          link = this.currentPost.parents[0].link;
        }

        const currentItem = items.find((item) => item.url === link);

        if (currentItem && currentItem.children.length > 0) {
          value = currentItem.children;
        }

        if (value !== this.subMenu) {
          this.subMenu = value;

          if (value) {
            this.parent = this.currentPost.parents[0].post_title;
          }
        }
      }
    },
    toggle() {
      if (!this.subMenu) {
        return;
      }
      if (this.height === 0) {
        this.height = '100%';
      } else this.height = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-bar {
  align-items: center;
  background: var(--white);
  border-radius: calc(var(--bar-height) / 2) 0 0 calc(var(--bar-height) / 2);
  box-shadow: var(--shadow);
  display: grid;
  overflow: hidden;
  min-height: var(--bar-height);
  transition: height 0.8s $ease-custom;
  z-index: 1;

  &--submenu {
    align-items: start;
    cursor: pointer;

    @include mq(m) {
      cursor: initial;
    }

    @include mq(m) {
      align-items: center;
    }
  }

  > .link {
    margin-left: var(--spacer-bar-m);
  }
}
</style>
