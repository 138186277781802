<template>
  <div
    class="embed"
    v-html="data.value.src"
  />
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.block.embed {
  display: grid;

  > * {
    width: 100%;
  }

  @include mq(s) {
    grid-column: 2 / span 6;
  }

  @include mq(m) {
    grid-column: 4 / span 6;
  }
}
</style>
