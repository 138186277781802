<template>
  <transition name="fade">
    <div
      v-if="$mq.isTouchDevice && loaders"
      class="app-loader"
    >
      <div class="app-loader__line" />
      <div class="progress-materializecss">
        <div class="indeterminate" />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Loader',
  computed: {
    ...mapGetters(['loading']),
    loaders() {
      return this.loading;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
  .app-loader {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
  }

  .app-loader__wrapper {
    width: 100%;
    height: 100%;
  }

  .progress-materializecss {
    position: fixed;
    width: 100%;
    z-index: 100;
    height: 3px;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    background-color: transparent;
    margin: 0;
    overflow: hidden;

    .indeterminate {
      background-color: var(--black);

      &:before {
        content: "";
        position: absolute;
        background-color: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
        animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
          infinite;
      }
      &:after {
        content: "";
        position: absolute;
        background-color: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
        animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
          infinite;
        animation-delay: 1.15s;
      }
    }
  }

  @keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
  }

  @keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
  }
</style>
