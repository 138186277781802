<template>
  <div
    class="guide"
    :style="{'--zoom': `${$store.state.zoom * 100}%`}"
  >
    <div class="paths">
      <div
        v-for="(path, index) in data.value.paths"
        :key="index + path.name"
        :style="{backgroundColor: path.acf.color}"
        class="circle block--m"
      />
    </div>
    <Heading
      :value="data.value.heading"
      tag="h1"
    />
    <RichText
      v-if="data.value.type === 'text'"
      :value="data.value.text"
      theme="indent"
    />
    <div
      v-if="data.value.type === 'audio'"
      class="audio-controls"
    >
      <audio ref="audio">
        <source
          :src="data.value.audio"
          type="audio/mpeg"
        >
      </audio>
      <div class="icon-container">
        <Icon
          v-show="!playing"
          name="play"
          theme="max"
          @click.native="toggleAudio"
        />
        <Icon
          v-show="playing"
          name="pause"
          theme="max"
          @click.native="toggleAudio"
        />
      </div>
      <RichText
        :value="playing ? $labels().pause : $labels().play"
        typo="2"
      />
    </div>
    <Link
      v-if="data.value.link"
      :data="data"
      theme="full-width"
    />
  </div>
</template>

<script>
import Heading from '@/components/typo/heading';
import RichText from '@/components/typo/richtext';
import Link from '@/components/typo/link';
import Icon from '@/components/ui/icon';

export default {
  name: 'Guide',
  components: {
    Heading,
    RichText,
    Link,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playing: false,
    };
  },
  mounted() {
    if (this.data.value.type === 'audio') {
      this.$refs.audio.addEventListener('canplaythrough', () => {
        this.$refs.audio.play();

        this.playing = !this.$refs.audio.paused;
      });
    }
  },
  methods: {
    toggleAudio() {
      if (this.$refs.audio.paused) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
      this.playing = !this.$refs.audio.paused;
    },
  },
};
</script>

<style lang="scss">

.guide {
  display: flex;
  flex-direction: column;
  gap: var(--column-gap-m);
  height: 100%;
  padding-bottom: var(--column-gap-xxl);

  .heading {
    grid-column: span 1;
  }

  p, a, ul, ol {
    font-size: var(--zoom);

    * {
      font-size: 100%;
    }
  }

  em {
  font-style: italic;
  }

  .typo--p strong {
    font-size: 100%;
  }
}

.paths {
  display: grid;
  grid-template-columns: repeat(3, 45px);
  gap: var(--column-gap-s);

  .circle {
    width: 100%;
    height: 45px;
    border-radius: 50%;
  }
}

.audio-controls {
  display: flex;
  gap: var(--row-gap-l);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;
  min-height: 300px;

  .icon-container {
    width: 167px;
    max-width: 100%;
    aspect-ratio: 1 / 1;
  }
}

</style>
