<template>
  <header
    v-show="!$route.meta.hideHeader"
    :class="[
      'header',
      menuIsOpen ? 'header--menu' : false,
      overlay && overlayHeader ? 'header--overlay' : false,
    ]"
    :style="{ zIndex: navigationBar ? 1 : 3 }"
  >
    <div
      v-visible="!navigationBar"
      :class="['top-bar', overlay && overlayHeader ? false : 'invert']"
    >
      <router-link
        class="logo"
        :to="lang === defaultLang ? '/' : `/${lang}/`"
      >
        <inline-svg
          :src="
            require(`@/assets/svg/${$mq.isMobile ? 'logo-icon' : 'logo'}.svg`)
          "
        />
      </router-link>
      <Icon
        v-if="overlay"
        name="close"
        :fn="closeOverlay"
      />
      <div
        v-else
        class="right-side"
      >
        <div class="highlight">
          <Link
            v-for="item in highlight"
            :key="item.id"
            :data="{
              url: item.url,
              title: item.content,
              target: item.target,
            }"
            theme="link"
          />
        </div>
        <Link
          :data="{
            fn: toggleMenu,
            title: menuIsOpen ? $labels().close_cta : $labels().menu_cta,
          }"
          theme="link"
        />
      </div>
    </div>
    <NavigationBar v-if="navigationBar" />
    <DatePicker
      v-if="navigationBar && $route.meta.typeName === 'event'"
      theme="desktop"
    />
  </header>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import Icon from '@/components/ui/icon';
import Link from '@/components/typo/link';
import NavigationBar from '@/components/ui/navigation-bar';
import DatePicker from '@/components/ui/navigation-bar/date-picker';

export default {
  name: 'Header',
  components: {
    Icon,
    Link,
    NavigationBar,
    DatePicker,
  },
  props: {
    navigationBar: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      overlayHeader: false,
    };
  },
  computed: {
    ...mapGetters([
      'menu',
      'menuIsOpen',
      'overlay',
      'transition',
      'lang',
      'defaultLang',
    ]),
    ...mapState(['filters']),
    highlight() {
      const value = this.menu('highlight');
      return value ? value.items : null;
    },
  },
  watch: {
    transition(value) {
      if (!value && this.overlay) {
        this.overlayHeader = true;
      }
    },
  },
  methods: {
    ...mapActions(['unlockView']),
    ...mapMutations(['SET_MENU']),
    toggleMenu() {
      this.SET_MENU(!this.menuIsOpen);
    },
    closeOverlay() {
      const { from } = this.$store.state.route;

      if (from.name && !from.meta.single) {
        this.$router.push(from.path);
      } else {
        const lang = this.lang !== 'it' ? `/${this.lang}` : '';

        this.$router.push(
          `${lang}/${this.$route.matched[0].props.default.slug}`,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: grid;
  grid-template-rows: min-content;
  padding: var(--spacer-s);
  position: fixed;
  width: 100%;

  .top-bar {
    align-items: center;
    border-radius: var(--border-radius);
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: var(--spacer-bar-s) var(--spacer-bar-m);
    transition: background 0.1s, color 0.1s;

    .logo {
      display: grid;
      margin-top: 6px;

      svg {
        height: 28px;
        width: auto;
      }
    }

    .icon--close {
      @media (hover: hover) {
        &:hover {
          opacity: 0.3;
        }
      }
    }

    .right-side {
      display: grid;
      gap: var(--column-gap-s);
      grid-auto-flow: column;
      margin-bottom: 1px;
    }

    .highlight {
      display: grid;
      gap: var(--column-gap-s);
      grid-auto-flow: column;
      color: var(--red);
    }
  }

  &--overlay {
    .top-bar {
      background: var(--white);
      box-shadow: var(--shadow);
    }
  }

  .date-picker {
    display: none;

    @include mq(m) {
      display: grid;
    }
  }
}
</style>
