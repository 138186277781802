<template>
  <div
    v-if="name"
    :class="['element', required ? 'element--required' : false]"
    :style="{ gridColumn: !$mq.isMobileS ? `span ${columns}` : 'initial' }"
  >
    <label
      class="typo--7"
      :for="name"
    >
      {{ $labels()[`form_${name}`] }}
    </label>
    <textarea
      :id="name"
      v-model="model"
      cols="1"
      :required="required"
      :name="name"
      :rows="rows"
    />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      model: '',
    };
  },
  watch: {
    model() {
      this.updateModel();
    },
  },
  methods: {
    updateModel() {
      this.$emit('update', this.name, this.model);
    },
  },
};
</script>

<style lang="scss" scoped>
  .element {
    display: grid;
    width: 100%;

    label {
      cursor: text;
    }

    textarea {
      border-bottom: var(--line) solid var(--alpha-black);

      @media (hover: hover) {
        &:hover {
          opacity: 1;
          border-color: var(--black);
        }
      }

      &:focus {
        opacity: 1;
        border-color: var(--black);
      }
    }

    &--required {
      label {
        &:after {
          content: "*";
        }
      }
    }
  }
</style>
