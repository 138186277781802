<template>
  <router-link
    :to="$relativeUrl(data.link)"
    :class="[
      'thumb',
      'thumb--default',
      pinned ? 'thumb--pinned' : false,
      `thumb--${data.type}`,
    ]"
  >
    <Figure
      :theme="pinned ? 'cover' : 'thumb'"
      :data="{
        value: {
          src: data.gds_featured_image,
        },
        settings: {},
      }"
    />
    <div class="details">
      <Duration
        :show-time="false"
        :duration="data.acf.duration.value"
      />
      <div
        v-if="space"
        class="space typo--p-small t-ellipsis"
      >
        {{ space }}
      </div>
    </div>
    <div
      :class="['title', pinned ? 'typo--3--trimmed' : 'typo--nav--trimmed']"
      v-html="data.title.rendered"
    />
    <div
      v-if="eventCat"
      class="event-category typo--6--trimmed invert"
    >
      {{ eventCat }}
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import Figure from '@/components/media/figure';
import Duration from '@/components/typo/duration';

export default {
  name: 'Thumb',
  components: {
    Duration,
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    pinned: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['lang']),

    space() {
      let value = false;

      if (this.data.gds_taxonomies.space) {
        value = this.data.gds_taxonomies.space[0].name;
      }

      return value;
    },
    eventCat() {
      let value = false;

      if (this.data.gds_taxonomies.event_category) {
        value = this.data.gds_taxonomies.event_category[0].name;
      }

      return value;
    },
  },
};
</script>

<style lang="scss">
.thumb {
  display: grid;
  row-gap: calc(var(--row-gap-xs) * 2);
  margin-bottom: var(--spacer-thumb);
  position: relative;

  &--default {
    .details {
      column-gap: var(--column-gap-s);
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);
    }

    .title {
      @include mq(m) {
        max-width: 380px;
      }
    }
  }

  &--exhibition {
    .title {
      @include mq(m) {
        max-width: 440px;
      }
    }
  }

  &--pinned {
    .title {
      max-width: initial;
    }
  }

  .event-category {
    position: absolute;
    top: var(--spacer-s);
    left: var(--spacer-s);
    padding: var(--spacer-s);
  }
}
</style>
