<template>
  <div
    :class="[
      'table',
      data.settings.gap ? `table--gap` : false,
      columns !== 1 ? `table--columns` : false,
    ]"
  >
    <div
      v-for="(column, columnIndex) in columns"
      :key="columnIndex"
      class="column"
    >
      <div class="rows">
        <div
          v-for="(row, rowIndex) in columns !== 1
            ? column.rows
            : data.value.rows"
          :key="rowIndex"
          class="row"
        >
          <header class="typo--6">
            <div class="typo--6--trimmed">
              {{ row.key }}
            </div>
            <div
              v-if="row.value && row.value !== ''"
              class="dots"
              :style="{
                borderImageSource: `url(${require(`@/assets/svg/dot-${data.settings.color ? data.settings.color : 'black'}.svg`)})`,
              }"
            />
            <div class="value typo--6--trimmed">
              {{ row.value }}
            </div>
          </header>
          <Richtext
            v-if="row.description !== ''"
            :value="row.description"
          />
        </div>
      </div>
      <Richtext
        v-if="data.value.notes !== '' && columns === 1"
        :value="data.value.notes"
        typo="caption"
      />
    </div>
  </div>
</template>

<script>
import Richtext from '@/components/typo/richtext';

export default {
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    columns() {
      let value = 1;

      if (this.data.settings.layout === 'columns') {
        value = this.data.value.columns;
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .table {
    @include mq(s) {
      grid-column: 2 / span 6;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }

    &--columns {
      align-items: start;
      display: grid;
      column-gap: var(--column-gap-s);
      // grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: calc(var(--row-gap-xs) * 2);

      &.table--gap {
        row-gap: var(--row-gap-l);
      }

      @include mq(m) {
        grid-column: 1 / -1;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      .column {
        margin-right: var(--spacer-l);
      }
    }

    .column {
      display: grid;
      gap: var(--row-gap-m);
    }

    .rows {
      display: grid;
      gap: calc(var(--row-gap-xs) * 2);

      .row {
        display: grid;
        gap: var(--row-gap-xs);

        header {
          align-items: end;
          display: grid;
          grid-template-columns: auto minmax(0, 1fr) auto;

          .dots {
            border: solid transparent;
            border-image-repeat: round;
            border-image-slice: 5 8;
            border-image-width: 0 0 0.15em 0;
          }

          .value {
            margin-left: var(--spacer-xs);
          }
        }
      }
    }

    &--gap {
      .rows {
        gap: var(--row-gap-l);
      }
    }
  }
</style>
