/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
/* eslint-disable no-undef */
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import { InlineSvgPlugin } from 'vue-inline-svg';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGtag from 'vue-gtag';
import VCalendar from 'v-calendar';
import axios from 'axios';
import 'normalize.css';

import labels from '@/assets/js/labels';
import App from './App.vue';

import '@/assets/js/eventBus';
import '@/assets/js/mq';
import '@/assets/js/layout';

Vue.config.productionTip = false;

Vue.use(InlineSvgPlugin);
Vue.use(Vue2TouchEvents);
Vue.use(VCalendar);

(async () => {
  if (typeof __VUE_WORDPRESS__ === 'undefined') {
    const axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_PROXY_TARGET}${process.env.VUE_APP_BASE_URL}wp-json/gds/`,
    });
    try {
      const { data: radl } = await axiosInstance.get('radl/');
      window.__VUE_WORDPRESS__ = radl;
    } catch (e) {
      console.error(e);
    }
  }

  const router = require('./router');
  const store = require('./store');
  sync(store.default, router.default);
  Vue.use(labels, store.default);

  const { options } = __VUE_WORDPRESS__.state;
  if (
    options
    && options.extra
    && options.extra.ga_id
    && process.env.NODE_ENV !== 'development'
  ) {
    Vue.use(
      VueGtag,
      {
        config: {
          id: options.extra.ga_id,
          params: {
            anonymize_ip: true,
          },
        },
        disableScriptLoad: true,
        bootstrap: true,
      },
      router.default,
    );
  }

  const { url: baseUrl } = __VUE_WORDPRESS__.routing;
  Vue.prototype.$relativeUrl = (url) => (url
    ? process.env.NODE_ENV === 'development'
        && process.env.VUE_APP_PROXY_TARGET
      ? url
        .replace(process.env.VUE_APP_PROXY_TARGET, '')
        .replace(baseUrl, '')
        .replace(process.env.VUE_APP_BASE_URL, '/')
      : url.replace(baseUrl, '').replace(process.env.VUE_APP_BASE_URL, '/')
    : false);

  Vue.directive('visible', (el, binding) => {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
  });

  const app = new Vue({
    router: router.default,
    store: store.default,
    render: (h) => h(App),
  });

  router.default.onReady(() => app.$mount('#app'));
})();
