<template>
  <div
    id="app"
    :class="{ loading: loading }"
  >
    <Loader />
    <div class="header-container">
      <AppHeader navigation-bar />
      <transition name="vertical-clip">
        <AppMenu v-show="menuIsOpen" />
      </transition>
      <AppHeader />
    </div>
    <div class="page-container">
      <router-view :key="path()" />
      <AppFooter />
    </div>
    <Transition
      :css="false"
      appear
      mode="out-in"
      @enter="overlayEnter"
      @leave="overlayLeave"
    >
      <router-view
        :key="$route.path"
        name="single"
      />
    </Transition>
    <div
      v-show="overlay"
      class="overlay-bg"
    />
    <div
      v-show="overlay"
      ref="spacer"
      class="overlay-spacer"
    >
      <Link
        v-show="!transition"
        theme="full--background"
        :data="{
          fn: closeOverlay,
          title: $labels().back_cta,
        }"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { animate } from 'motion';
import debounce from 'lodash.debounce';

import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';
import { observerThresholds } from '@/assets/js/utils';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import AppMenu from '@/components/ui/menu';
import Loader from '@/components/ui/loader';
import Link from '@/components/typo/link';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    AppMenu,
    Link,
    Loader,
  },
  mixins: [lazyload],
  data() {
    return {
      basePath: '',
    };
  },
  computed: {
    ...mapGetters([
      'currentPost',
      'options',
      'menuIsOpen',
      'overlay',
      'transition',
      'lang',
      'loading',
    ]),
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 0),
    );

    document.fonts.ready.then(() => {
      this.$store.commit('SET_FONTS', true);
    });

    const observer = new IntersectionObserver(this.handleObserver, {
      threshold: observerThresholds(100),
    });

    observer.observe(this.$refs.spacer);
  },
  methods: {
    path() {
      const { from } = this.$store.state.route;

      if (this.$route.meta.single) {
        if (!from.meta.single) {
          this.basePath = from.path;
          return this.basePath;
        }
        return this.basePath;
      }

      return this.$route.path;
    },
    overlayEnter(el, done) {
      el.style.top = 'calc(var(--vh) * 100';
      this.$store.commit('SET_TRANSITION', true);

      animate(
        (progress) => {
          const opacity = progress;
          document.documentElement.style.setProperty('--opacity', opacity);
        },
        { duration: 0.4 },
      );
      animate(el, { top: 0 }, { duration: 0.4 }).finished.then(() => {
        this.$store.commit('SET_TRANSITION', false);
        done();
      });
    },
    overlayLeave(el, done) {
      this.$store.commit('SET_TRANSITION', true);

      const pastOpacity = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue(
          '--opacity',
        ),
      );

      const prevScroll = window.pageYOffset;
      const single = el;
      single.style.position = 'fixed';
      single.style.height = 'calc(var(--vh) * 100)';
      single.style.overflowY = 'hidden';
      single.children[0].style.position = 'absolute';
      single.children[0].style.top = `-${prevScroll}px`;

      animate(
        (progress) => {
          const opacity = pastOpacity - progress;
          document.documentElement.style.setProperty(
            '--opacity',
            Math.max(opacity, 0),
          );
        },
        { duration: 0.4 },
      );

      animate(
        el,
        { top: 'calc(var(--vh) * -100' },
        { duration: 0.6 },
      ).finished.then(() => {
        if (!this.$route.meta.single) {
          this.$store.dispatch('unlockView', {
            view: '.page-container',
          });
        }

        this.$store.commit('SET_TRANSITION', false);
        done();
      });
    },
    handleObserver(entry) {
      if (this.$route.meta.single && !this.transition) {
        const opacity = 1 - entry[0].intersectionRatio;
        document.documentElement.style.setProperty(
          '--opacity',
          Math.max(opacity, 0.6),
        );
      }
    },
    closeOverlay() {
      const { from } = this.$store.state.route;

      if (from.name && !from.meta.single) {
        this.$router.push(from.path);
      } else {
        const lang = this.lang !== 'it' ? `/${this.lang}` : '';

        this.$router.push(
          `${lang}/${this.$route.matched[0].props.default.slug}`,
        );
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/style.scss";

  .app-loaded {
    #loader {
      display: none;
    }
  }

  .loading {
    cursor: wait;
  }

  .page-container {
    align-content: space-between;
    background: var(--white);
    display: grid;
    min-height: calc(var(--vh) * 100);
  }

  .overlay-spacer {
    padding-bottom: calc(var(--vh) * 25);

    .link {
      position: relative;
      z-index: 1;
    }
  }

  .overlay-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: var(--black);
    opacity: var(--opacity, 0);
  }
</style>
