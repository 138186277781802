<template>
  <div
    v-if="!empty"
    class="sidebar"
  >
    <aside>
      <Section
        v-for="(section, index) in sections"
        :key="index"
        :data="section.data"
        :type="section.type"
        :no-label="post.type === 'work' && section.type === 'information'"
      />
      <div
        v-if="tickets"
        class="tickets"
      >
        <Link
          :data="{
            url: ticketsLink.url,
            title: ticketsLink.content,
            target: ticketsLink.target,
          }"
          theme="highlight"
        />
      </div>
    </aside>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Link from '@/components/typo/link';
import Section from '@/components/ui/sidebar/section';

export default {
  components: {
    Link,
    Section,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    allowedSections: {
      type: Array,
      default: () => [],
    },
    tickets: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['menu']),
    ticketsLink() {
      const value = this.menu('highlight');
      return value ? value.items[0] : null;
    },
    sections() {
      const value = [];

      this.allowedSections.forEach((section) => {
        if (this.post.acf[section]) {
          value.push({ type: section, data: this.post.acf[section] });
        }
        if (section === 'space') {
          value.push({
            type: section,
            data: this.post.gds_taxonomies.space
              ? this.post.gds_taxonomies.space[0]
              : {
                value: {
                  text: '',
                },
              },
          });
        }
      });

      return value;
    },
    empty() {
      let value = true;

      this.sections.forEach((section) => {
        const { data } = section;

        if (section.type === 'duration') {
          value = false;
        }
        if (section.type === 'space' && data) {
          value = false;
        }
        if (data.value) {
          if (data.value.text && data.value.text !== '') {
            value = false;
          }
        }
        if (this.tickets) {
          value = false;
        }
      });

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .sidebar {
    align-self: start;
    column-gap: var(--column-gap-s);
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: repeat(var(--main-columns), minmax(0, 1fr));
    margin-bottom: var(--spacer-xl);
    margin-top: var(--spacer-m);
    top: calc(var(--bar-height) + 2 * var(--spacer-s));
    z-index: 1;

    @include mq(m) {
      grid-row: 2;
      margin-bottom: 0;
      position: sticky;
    }

    aside {
      display: grid;
      row-gap: var(--row-gap-m);
      grid-column: 1 / -1;

      @include mq(m) {
        grid-column: 1 / span 2;
      }

      .tickets {
        display: grid;
        row-gap: var(--row-gap-xs);
        justify-items: start;
      }
    }
  }
</style>
