<template>
  <div
    :class="[
      'single--exhibition',
      noCover ? 'single--exhibition--no-cover' : false,
    ]"
  >
    <header>
      <Cover
        v-if="!noCover"
        :data="post.acf.cover[0]"
      />
      <Heading
        :value="post.title.rendered"
        typo="3"
        tag="h1"
        theme="full"
      />
      <Richtext
        v-if="subtitle"
        class="subtitle"
        :value="subtitle"
        typo="4"
      />
    </header>
    <Sidebar
      :allowed-sections="sidebarSections"
      :post="post"
      :tickets="tickets"
    />
    <main>
      <Gutenberg
        theme="no-padding"
        :data="post.gds_blocks"
      />
    </main>
  </div>
</template>

<script>
import Cover from '@/components/media/cover';
import Gutenberg from '@/components/blocks';
import Heading from '@/components/typo/heading';
import Richtext from '@/components/typo/richtext';
import Sidebar from '@/components/ui/sidebar';

export default {
  components: {
    Cover,
    Gutenberg,
    Heading,
    Richtext,
    Sidebar,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sidebarSections: ['duration', 'space', 'curation', 'information'],
    };
  },
  computed: {
    subtitle() {
      let value = false;
      const subtitle = this.post.acf.subtitle.value.text;

      if (subtitle !== '') {
        value = subtitle;
      }

      return value;
    },
    noCover() {
      return !this.post.acf.cover.length > 0;
    },
    tickets() {
      let value = true;

      const today = new Date();
      const start = new Date(this.post.acf.duration.value.start.date);
      const end = new Date(this.post.acf.duration.value.end.date);

      if (start) {
        if (start > today) {
          value = false;
        }
      }

      if (end) {
        if (end < today) {
          value = false;
        }
      }

      if (!this.post.acf.tickets.value.tickets) {
        value = false;
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .single {
    &--exhibition {
      padding: var(--spacer-s);
      padding-top: 0;

      header {
        --spacer-xxl: 108px;

        column-gap: var(--column-gap-s);
        display: grid;
        grid-column: 1 / -1;
        grid-template-columns: repeat(var(--main-columns), minmax(0, 1fr));
        margin-bottom: var(--spacer-xxl);

        .heading {
          margin-top: var(--row-gap-m);
        }

        .subtitle {
          margin-top: var(--row-gap-l);
        }
      }

      &--no-cover {
        header {
          .heading {
            margin-top: calc(var(--bar-height) + 2 * var(--spacer-s));
          }
        }
      }

      main {
        grid-column: 1 / -1;
        pointer-events: none;
        z-index: 1;

        @include mq(m) {
          grid-row: 2;
        }

        .archive-heading {
          margin-top: var(--row-gap-xxl);
        }

        .archive {
          @include mq(m) {
            /deep/ .thumb:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
