<template>
  <nav class="menu">
    <div class="first-row">
      <div>
        <Link
          v-for="(item, index) in first"
          :key="item.id"
          :data="{
            url: item.url,
            title: item.content,
            target: item.target,
          }"
          theme="navigation"
          :style="{ zIndex: index }"
        />
      </div>
      <div class="second-column">
        <Link
          v-for="(item, index) in second"
          :key="item.id"
          :data="{
            url: item.url,
            title: item.content,
            target: item.target,
          }"
          theme="navigation"
          :style="{ zIndex: index }"
        />
      </div>
    </div>
    <div class="bottom">
      <div class="social">
        <Link
          v-for="item in social"
          :key="item.id"
          :data="{
            url: item.url,
            title:
              $mq.isMobile && item.acf.mobile_label !== ''
                ? item.acf.mobile_label
                : item.content,
            target: item.target,
          }"
          theme="small"
        />
      </div>
      <LanguageSelector />
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Link from '@/components/typo/link';
import LanguageSelector from '@/components/ui/language-selector';

export default {
  name: 'Menu',
  components: {
    Link,
    LanguageSelector,
  },
  computed: {
    ...mapGetters(['menu']),
    first() {
      const value = this.menu('navigation-1');
      return value ? value.items : null;
    },
    second() {
      const value = this.menu('navigation-2');
      return value ? value.items : null;
    },
    social() {
      const value = this.menu('social');
      return value ? value.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .menu {
    align-content: space-between;
    background: var(--red);
    box-shadow: var(--shadow);
    // clip-path: inset(0px 0px 0px 0px);
    display: grid;
    height: calc(var(--vh) * 100);
    padding: var(--spacer-s);
    padding-top: calc(var(--bar-height) + var(--spacer-s) * 2);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    @include mq(m) {
      height: initial;
      row-gap: var(--row-gap-xxl);
    }

    .first-row {
      align-items: start;
      display: grid;
      grid-template-columns: repeat(var(--main-columns), minmax(0, 1fr));
      column-gap: var(--column-gap-s);
      grid-column: 1 / -1;
      row-gap: var(--row-gap-xs);

      > * {
        display: grid;
        grid-column: 1 / -1;
        justify-items: start;
        row-gap: var(--row-gap-xs);

        @include mq(m) {
          grid-column: span 4;
        }
      }

      .second-column {
        @include mq(m) {
          grid-column: span 8;
        }
      }
    }

    .bottom {
      align-items: end;
      column-gap: var(--column-gap-s);
      display: grid;
      grid-auto-flow: column;
      grid-column: 1 / -1;
      justify-content: space-between;

      @include mq(m) {
        grid-template-columns: repeat(
          calc(var(--main-columns)),
          minmax(0, 1fr)
        );
        justify-content: initial;
      }

      .social {
        display: grid;
        column-gap: var(--column-gap-m);
        grid-auto-flow: column;

        @include mq(m) {
          grid-column: 1 / -2;
          column-gap: var(--column-gap-s);
          grid-template-columns: repeat(
            calc(var(--main-columns) - 1),
            minmax(0, 1fr)
          );

          > * {
            grid-column: span 2;
          }
        }
      }

      .language-selector {
        @include mq(m) {
          justify-self: end;
        }
      }
    }
  }
</style>
