var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"height"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.datePicker),expression:"datePicker"}],class:['date-picker', 'typo--6', ( _obj = {}, _obj[("date-picker--" + _vm.theme)] = _vm.theme, _obj )]},[_c('div',[_c('v-date-picker',_vm._b({scopedSlots:_vm._u([{key:"header-left-button",fn:function(){return [_c('Icon',{attrs:{"name":"arrow-left"}})]},proxy:true},{key:"header-right-button",fn:function(){return [_c('Icon',{attrs:{"name":"arrow-right"}})]},proxy:true}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-date-picker',_vm.options,false)),_c('div',{staticClass:"buttons-row"},_vm._l((_vm.archiveToggle[_vm.lang]),function(link){return _c('Link',{key:link.title,class:['invert', { active: link.url === _vm.$route.path }],attrs:{"data":link.url === _vm.$route.path
              ? { title: link.title, fn: function () { return _vm.SET_EVENTS_DATE(null); } }
              : {
                title: link.title,
                url: link.url,
              },"theme":"small--invert"}})}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }