<template>
  <div :class="['newsletter', 'full', response ? 'newsletter--response' : false]">
    <Heading
      typo="2"
      :theme="!$mq.isMobile ? 'indent' : false"
      :value="data.value.heading"
    />
    <form @submit.prevent="submit">
      <div
        tabindex="-1"
        class="top"
      >
        <input
          id="email"
          v-model="email"
          type="email"
          name="email"
          :placeholder="data.value.placeholder"
          size="1"
          class="typo--2"
          required
          @input="reset"
        >
      </div>
      <div class="separator typo--2" />
      <div class="bottom">
        <div
          tabindex="-1"
          class="privacy"
        >
          <input
            id="privacy-policy"
            v-model="privacyPolicyAccepted"
            type="checkbox"
            required
          >
          <label for="privacy-policy">
            <Richtext
              typo="7--trimmed"
              :value="data.value.privacy_text"
            />
          </label>
        </div>
        <Link
          v-if="!response"
          tabindex="-1"
          class="submit"
          theme="highlight"
          :disabled="!privacyPolicyAccepted"
          :data="{
            fn: () => {},
            title: $labels().newsletter_cta,
          }"
        />
        <div
          v-else
          class="typo--5--trimmed"
        >
          {{ $labels()[`newsletter_${response}_message`] }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Heading from '@/components/typo/heading';
import Link from '@/components/typo/link';
import Richtext from '@/components/typo/richtext';
// import sendinblue from 'sib-api-v3-sdk';

export default {
  components: {
    Heading,
    Link,
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      already: false,
      done: false,
      email: '',
      privacyPolicyAccepted: false,
      response: false,
    };
  },
  computed: {
    ...mapGetters(['options']),
  },
  methods: {
    submit() {
      const request = {
        fn: 'sendinblue',
        params: {
          list_id: this.data.settings.list_id,
          email: this.email,
        },
      };

      this.$store
        .dispatch('sendRequest', request)
        .then((response) => {
          const { data } = response;

          if (
            data.contacts
              && data.contacts.success.length > 0
              && data.contacts.success.includes(this.email)
          ) {
            this.response = 'success';
          } else if (data.code && data.code === 'invalid_parameter') {
            this.response = 'already';
          } else {
            this.response = 'error';
          }
        })
        .catch((error) => {
          this.response = 'error';
          console.log(error);
        });
    },
    reset() {
      if (this.response) {
        this.response = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .newsletter {
    background: var(--red);
    column-gap: var(--column-gap-s);
    display: grid;
    grid-template-columns: repeat(calc(var(--main-columns)), minmax(0, 1fr));
    row-gap: var(--row-gap-m);

    &.block {
      padding: var(--spacer-l) var(--spacer-s);

      @include mq(s) {
        padding-top: var(--spacer-m);
        padding-bottom: var(--spacer-xxl);
      }
    }

    @include mq(m) {
      row-gap: var(--row-gap-s);
    }

    > * {
      grid-column: 1 / -1;
    }

    form {
      align-items: end;
      display: grid;
      opacity: 0.3;

      &:focus-within {
        opacity: 1;

        .privacy {
          visibility: visible;
        }

        .submit {
          visibility: visible;
        }

        ::placeholder {
          opacity: 0.3;
        }
      }

      .submit {
        visibility: hidden;
      }

      .top,
      .separator {
        grid-area: 1 / 1;
      }

      .top {
        align-items: center;
        column-gap: var(--spacer-s);
        display: grid;
        grid-template-columns: minmax(0, 1fr) max-content;

        @include mq(m) {
          align-items: end;
        }

        .submit {
          opacity: 0.3;
          pointer-events: none;
        }

        input:valid + .submit {
          opacity: 1;
          pointer-events: all;
        }
      }

      .separator {
        border-bottom: var(--line) solid;
        pointer-events: none;

        @include mq(s) {
          margin-bottom: 0.1em;
        }
      }

      .bottom {
        align-items: start;
        display: grid;
        justify-content: space-between;
        margin-top: var(--row-gap-s);
        row-gap: var(--row-gap-m);

        @include mq(s) {
          grid-auto-flow: column;
          margin-top: 0;
        }
      }

      .privacy {
        align-items: center;
        column-gap: calc(var(--column-gap-xs) * 2);
        display: grid;
        justify-content: start;
        grid-auto-flow: column;
        // visibility: hidden;

        > * {
          cursor: pointer;
        }
      }
    }

    &--response {
      form {
        opacity: 1;
      }
    }
  }
</style>
