<template>
  <div class="single--event">
    <article>
      <Heading
        :value="post.title.rendered"
        typo="3"
        tag="h1"
        theme="full"
      />
      <Sidebar
        :allowed-sections="sidebarSections"
        :post="post"
        :tickets="post.acf.tickets.value.tickets"
      />
      <main>
        <Gutenberg
          theme="no-padding"
          :data="post.gds_blocks"
        />
      </main>
    </article>
    <footer>
      <Heading
        :value="$labels().events_archive_title"
        typo="nav-l"
      />
      <Archive
        :data="{
          value: {
            post_type: 'event',
          },
          settings: {
            posts_per_page: $mq.isMobile ? 4 : 3,
            filters: ['agenda:scheduled'],
            exclude: [post.id]
          },
        }"
      />
    </footer>
  </div>
</template>

<script>
import Archive from '@/components/blocks/archive';
import Gutenberg from '@/components/blocks';
import Heading from '@/components/typo/heading';
import Sidebar from '@/components/ui/sidebar';

export default {
  components: {
    Archive,
    Gutenberg,
    Heading,
    Sidebar,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sidebarSections: ['duration', 'space', 'curation', 'information'],
    };
  },
};
</script>

<style lang="scss" scoped>
  .single {
    &--event {
      padding: var(--spacer-s);
      padding-top: calc(var(--bar-height) + 2 * var(--spacer-s));
      row-gap: var(--row-gap-xxl);

      article {
        display: grid;
        grid-column: 1 / -1;

        .heading {
          --spacer-xxl: 108px;
          margin-bottom: var(--spacer-xxl);

          @include mq(m) {
            margin-bottom: var(--spacer-l);
          }
        }

        main {
          grid-column: 1 / -1;
          pointer-events: none;

          @include mq(m) {
            grid-row: 2;
          }
        }

        .sidebar {
          align-self: start;
          grid-column: 1 / -1;

          @include mq(m) {
            grid-row: 2;
          }
        }
      }

      footer {
        display: grid;
        grid-column: 1 / -1;
        row-gap: var(--row-gap-m);
        z-index: 1;
      }
    }
  }
</style>
