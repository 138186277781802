<template>
  <transition name="height">
    <div
      v-show="datePicker"
      :class="['date-picker', 'typo--6', { [`date-picker--${theme}`]: theme }]"
    >
      <div>
        <v-date-picker
          v-model="model"
          v-bind="options"
        >
          <template #header-left-button>
            <Icon name="arrow-left" />
          </template>
          <template #header-right-button>
            <Icon name="arrow-right" />
          </template>
        </v-date-picker>

        <div class="buttons-row">
          <Link
            v-for="link in archiveToggle[lang]"
            :key="link.title"
            :class="['invert', { active: link.url === $route.path }]"
            :data="
              link.url === $route.path
                ? { title: link.title, fn: () => SET_EVENTS_DATE(null) }
                : {
                  title: link.title,
                  url: link.url,
                }
            "
            theme="small--invert"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import Icon from '@/components/ui/icon';
import Link from '@/components/typo/link';

export default {
  props: {
    theme: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    archiveToggle: {
      it: [
        {
          url: '/eventi/in-programma',
          title: 'In programma',
        },
        {
          url: '/eventi/archivio',
          title: 'Archivio',
        },
      ],
      en: [
        {
          url: '/en/events/scheduled',
          title: 'Scheduled',
        },
        {
          url: '/en/events/archive',
          title: 'Archive',
        },
      ],
    },
  }),
  watch: {
    current() {
      this.SET_EVENTS_DATE(null);
    },
  },
  computed: {
    ...mapState(['datePicker', 'eventsDate']),
    ...mapGetters(['lang']),
    model: {
      get() {
        return this.eventsDate;
      },
      set(value) {
        this.setEventsDate(value);
      },
    },
    options() {
      return {
        color: 'black',
        minDate: this.$route.meta.agenda === 'scheduled' ? new Date() : null,
        maxDate: this.$route.meta.agenda === 'archive' ? new Date() : null,
        locale: this.lang,
        masks: {
          weekdays: 'WWW',
        },
        isExpanded: this.fullWidth,
      };
    },
    current() {
      return this.$route.meta.agenda === 'scheduled';
    },
  },
  mounted() {
    const { query } = this.$route;
    if (query.date) {
      this.SET_EVENTS_DATE(new Date(parseInt(query.date, 10)));
    }
  },
  methods: {
    ...mapMutations(['SET_EVENTS_DATE']),
    setEventsDate(value) {
      if (value) {
        this.$router.replace({
          query: { ...this.$route.query, date: value.getTime() },
        });
        this.SET_EVENTS_DATE(value);
      } else {
        this.$router.replace({
          query: { ...this.$route.query, date: undefined },
        });
        this.SET_EVENTS_DATE(value);
      }
    },
  },
  components: { Icon, Link },
};
</script>

<style lang="scss" scoped>
.date-picker {
  &--desktop {
    background: var(--white);
    box-shadow: var(--shadow);
    justify-self: end;
  }

  > div {
    display: grid;
    gap: var(--row-gap-s);
    @include mq(m) {
      padding: var(--spacer-bar-m);
    }
  }
}
.buttons-row {
  display: grid;
  gap: var(--spacer-s);
  // grid-auto-flow: column;
  justify-items: start;
}
</style>

<style lang="scss">
div.vc {
  &-container {
    --rounded: 0;
    --rounded-lg: 0;
    --text-sm: 12px;
    --text-lg: initial;

    font: inherit;
    border: none;

    .icon {
      pointer-events: none;
    }

    &.vc-black {
      --accent-100: var(--black);
      --accent-200: var(--black);
      --accent-300: var(--black);
      --accent-400: var(--black);
      --accent-500: var(--black);
      --accent-600: var(--black);
      --accent-700: var(--black);
      --accent-800: var(--black);
      --accent-900: var(--black);

      --black-100: var(--black);
      --black-200: var(--black);
      --black-300: var(--black);
      --black-400: var(--black);
      --black-500: var(--black);
      --black-600: var(--black);
      --black-700: var(--black);
      --black-800: var(--black);
      --black-900: var(--black);

      --gray-100: var(--white);
      --gray-200: var(--white);
      --gray-300: var(--alpha-black);
      --gray-400: var(--alpha-black);
      --gray-500: var(--black);
      --gray-600: var(--black);
      --gray-700: var(--black);
      --gray-800: var(--black);
      --gray-900: var(--black);
    }

    .vc {
      &-header {
        padding-bottom: var(--spacer-s);
      }
      &-weekday {
        padding-bottom: var(--spacer-s);
      }
      &-day {
        min-width: 32px;
        min-height: 32px;

        @include mq(m) {
          min-width: 40px;
          min-height: 40px;
        }
      }
      &-day-content {
        margin-top: 0.2em;
      }
      &-focusable {
        &:focus,
        &:hover {
          background-color: initial;
        }
      }
      &-arrows-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
