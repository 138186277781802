<template>
  <div :class="['menu', { 'menu--expanded': expanded }]">
    <div
      v-if="$mq.isMobile && parent"
      class="typo--5--trimmed parent"
      @click="toggleBar"
    >
      {{ parent }}
    </div>
    <Icon
      name="arrow-down"
      size="s"
      theme="minimal"
    />
    <div
      v-if="items.length"
      class="menu-items"
      @click="toggleBar"
    >
      <Link
        v-for="item in items"
        v-show="!item.hide"
        :key="item.id"
        ref="link"
        :data="{
          url: item.url,
          title: item.content,
          target: item.target,
        }"
        theme="link--sub"
      />
    </div>
    <Filters
      v-if="$route.meta.typeName === 'event'"
      class="menu-items"
      @click.native="toggleBar"
    />
    <Link
      v-if="$route.meta.typeName === 'event'"
      :icon="{
        name: 'arrow-down',
        position: 'after',
        rotation: datePicker ? 180 : 0,
      }"
      class="date-picker-toggle"
      theme="icon"
      :data="{ fn: TOGGLE_DATE_PICKER, title: $labels().date_picker_cta }"
    />
    <DatePicker :full-width="true" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Link from '@/components/typo/link';
import Icon from '@/components/ui/icon';
import Filters from '@/components/ui/navigation-bar/filters';
import DatePicker from '@/components/ui/navigation-bar/date-picker';

export default {
  components: {
    Link,
    Filters,
    Icon,
    DatePicker,
  },
  props: {
    items: {
      type: [Boolean, Array],
      required: true,
    },
    parent: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['datePicker']),
  },
  methods: {
    ...mapMutations(['TOGGLE_DATE_PICKER']),
    toggleBar() {
      this.$emit('toggleBar');
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  display: grid;
  gap: var(--column-gap-m);
  position: relative;
  min-height: inherit;
  padding: 14px var(--spacer-bar-m);
  padding-top: 0;
  align-items: center;
  max-height: calc(100 * var(--vh) - var(--bar-height) - var(--spacer-s) * 2);
  overflow-y: auto;
  overscroll-behavior: contain;

  @include mq(m) {
    padding: initial;
    grid-auto-flow: column;
    justify-content: space-between;
    overflow-y: initial;
  }

  &--expanded {
    .icon {
      transform: rotate(180deg);
    }
  }

  .menu-items {
    align-items: center;
    display: grid;
    gap: var(--column-gap-m);
    justify-content: start;
    white-space: nowrap;
    width: 100%;

    @include mq(m) {
      overflow-x: auto;
      overflow-y: hidden;
      grid-auto-flow: column;
      padding: 14px var(--spacer-bar-m);
    }
  }

  .date-picker-toggle {
    margin-top: var(--row-gap-m);

    @include mq(m) {
      margin-top: initial;
      padding: 14px var(--spacer-bar-m);
      padding-right: 0;
    }
  }

  .date-picker {
    @include mq(m) {
      display: none;
    }
  }

  .parent {
    padding-top: 14px;
    padding-bottom: var(--spacer-l);
  }

  .icon {
    pointer-events: none;
    position: absolute;
    right: var(--spacer-bar-m);
    top: 12px;
    transition: transform 0.2s ease;

    @include mq(m) {
      display: none;
    }
  }
}
</style>
