<template>
  <section
    v-if="type === 'duration' || data.name || data.value.text"
    class="section"
  >
    <Heading
      v-if="!noLabel"
      :value="$labels()[`${type}_label`]"
      typo="7"
    />
    <Duration
      v-if="type === 'duration'"
      :duration="data.value"
    />
    <Richtext
      v-else
      :value="data.name || data.value.text"
      typo="p-small"
    />
  </section>
</template>

<script>
import Heading from '@/components/typo/heading';
import Richtext from '@/components/typo/richtext';
import Duration from '@/components/typo/duration';

export default {
  components: {
    Duration,
    Heading,
    Richtext,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .section {
    display: grid;
    row-gap: var(--row-gap-xs);
  }
</style>
