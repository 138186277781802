<template>
  <div class="filters">
    <Link
      :data="{
        fn: () => {
          setFilter(null);
        },
        title: $labels().archive_filters_all,
      }"
      :theme="!filter ? 'link--straight' : 'link--sub'"
    />
    <Link
      v-for="cat in eventCategories"
      v-show="cat.count !== 0"
      :key="cat.id"
      :data="{
        fn: () => {
          setFilter(cat);
        },
        title: cat.name,
      }"
      :theme="filter === cat.name ? 'link--straight' : 'link--sub'"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import Link from '@/components/typo/link';

export default {
  components: {
    Link,
  },
  props: {
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['eventCategories']),
    ...mapState(['filter']),
  },
  mounted() {
    const { query } = this.$route;
    if (query.category) {
      this.SET_FILTER(
        Object.values(this.eventCategories).find(
          (category) => category.slug === query.category,
        ).name,
      );
    }
  },
  methods: {
    ...mapMutations(['SET_FILTER', 'TOGGLE_FILTERS']),
    setFilter(value) {
      if (value) {
        this.$router.replace({
          query: { ...this.$route.query, category: value.slug },
        });
        this.SET_FILTER(value.name);
      } else {
        this.$router.replace({
          query: { ...this.$route.query, category: undefined },
        });
        this.SET_FILTER(value);
      }
    },
  },
};
</script>
