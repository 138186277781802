<template>
  <div :class="['gutenberg', theme ? `gutenberg--${theme}` : false]">
    <component
      :is="components.get(parseBlockName(block.blockName))"
      v-for="(block, index) in data"
      :key="index"
      :class="[
        'block',
        spacing(block),
        index === data.length - 1 ? 'block--last' : false,
        `block--${index}`,
      ]"
      :data="
        block.blockName.includes('acf/')
          ? { ...block.attrs.data, position: index }
          : { ...block, position: index }
      "
    />
  </div>
</template>

<script>
import { parseBlockName } from '@/assets/js/utils';
import baseComponents from '@/assets/js/components';
import Group from '@/components/blocks/group';

export default {
  name: 'Gutenberg',
  props: {
    data: {
      type: Array,
      required: true,
    },
    theme: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      parseBlockName,
    };
  },
  computed: {
    components() {
      const value = baseComponents;

      value.set('group', Group);

      return value;
    },
  },
  methods: {
    spacing(block) {
      let value = false;
      const acf = block.blockName.includes('acf/');

      if (acf) {
        const spacing = block.attrs.data.settings?.spacing;

        if (spacing && spacing !== 'default') {
          value = `block--${spacing}`;
        }
      }

      return value;
    },
  },
};
</script>

<style lang="scss">
.gutenberg {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: repeat(var(--main-columns), minmax(0, 1fr));
  column-gap: var(--column-gap-s);
  padding: 0 var(--spacer-s);
  pointer-events: none;

  > * {
    pointer-events: all;
  }

  &--no-padding {
    padding: 0;

    .block {
      &:first-child {
        padding-top: 0;
      }
    }
  }
}

.block {
  grid-column: 1 / -1;

  &:not(.group):not(.spacer) {
    padding-top: var(--spacer-m);

    &.heading--5 {
      padding-top: var(--spacer-xl);
    }

    &.table {
      padding-top: var(--spacer-l);
    }

    &.block {
      &--l {
        padding-top: var(--spacer-xxl);

        &.link--huge {
          @include mq(m) {
            padding-top: 0;
          }
        }
      }
    }
  }

  &.block--0 {
    &.figure--full,
    &.gallery--full,
    &.video--full {
      padding-top: 0 !important;
    }
  }
}
</style>
