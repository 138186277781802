<template>
  <div class="spacer" />
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .spacer {
    height: var(--spacer-xxl);
  }
</style>
