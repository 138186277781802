<template>
  <cover
    :is="components.get(type ? type : data.acf_fc_layout)"
    :data="data"
    theme="cover"
  />
</template>

<script>
import Figure from '@/components/media/figure';
import Video from '@/components/media/video';

export default {
  components: {
    Figure,
    Video,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      components: new Map([
        ['image', Figure],
        ['video', Video],
      ]),
    };
  },
};
</script>

<style lang="scss" scoped></style>
