<template>
  <div class="single--work">
    <header>
      <Gallery
        v-if="post.acf.gallery.value.gallery.length > 1"
        theme="work"
        :data="post.acf.gallery"
      />
      <Figure
        v-else
        :data="{
          value: {
            src: post.gds_featured_image,
          },
          settings: {},
        }"
        theme="gallery--work"
        disable-ratio
      />
      <div class="headings">
        <Heading
          v-if="artist"
          :value="artist"
          typo="1-serif"
          :trim="false"
          tag="h2"
        />
        <Heading
          :value="title"
          typo="1-serif"
          :trim="false"
          tag="h1"
          class="title"
          :theme="post.acf.display_title.value.title ? false : 'italic'"
        />
      </div>
    </header>
    <Sidebar
      :allowed-sections="sidebarSections"
      :post="post"
      :tickets="false"
    />
    <main>
      <Gutenberg
        theme="no-padding"
        :data="post.gds_blocks"
      />
    </main>
  </div>
</template>

<script>
import Figure from '@/components/media/figure';
import Gallery from '@/components/media/gallery';
import Gutenberg from '@/components/blocks';
import Heading from '@/components/typo/heading';
import Sidebar from '@/components/ui/sidebar';

export default {
  components: {
    Figure,
    Gallery,
    Gutenberg,
    Heading,
    Sidebar,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sidebarSections: ['information'],
    };
  },
  computed: {
    artist() {
      let value = false;
      const taxonomies = this.post.gds_taxonomies;

      if (taxonomies.artist && taxonomies.artist.length > 0) {
        value = taxonomies.artist[0].name;
      }

      return value;
    },
    title() {
      let value = false;
      if (this.post.acf.display_title.value.title) {
        value = this.post.acf.display_title.value.title;
        return value;
      }
      value = this.post.title.rendered;

      if (this.post.acf.date && this.post.acf.date.value.date) {
        // const helper = document.createElement('div');
        // helper.innerHTML = value;
        // const inside = helper.querySelector('p');
        value += `,&nbsp;<span>${this.post.acf.date.value.date}</span>`;

        // value = inside.innerHTML;
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .single {
    &--work {
      padding: var(--spacer-s);

      header {
        --spacer-xxl: 108px;

        align-items: center;
        column-gap: var(--column-gap-s);
        display: grid;
        grid-column: 1 / -1;
        height: calc(var(--vh) * 100 - (var(--spacer-s) * 2));
        margin-bottom: var(--spacer-xxl);
        position: relative;

        @include mq(m) {
          margin-bottom: var(--spacer-l);
        }

        > * {
          grid-area: 1 / 1;
        }

        .headings {
          align-self: end;
          margin-right: calc(var(--spacer-l) * 2);
        }

        .title {
          /deep/ span {
            font-style: initial;
          }
        }
      }

      main {
        grid-column: 1 / -1;
        pointer-events: none;
        z-index: 1;

        @include mq(m) {
          grid-row: 2;
        }
      }

      .sidebar {
        align-self: start;
        grid-column: 1 / -1;

        @include mq(m) {
          grid-row: 2;
        }
      }
    }
  }
</style>
