<template>
  <div :class="['video', computedTheme ? `video--${computedTheme}` : false]">
    <div
      v-if="source === 'url'"
      key="url"
      class="player-url"
    >
      <video
        ref="player"
        playsinline
        crossorigin
        class="plyr"
      />

      <video
        v-if="source === 'file'"
        ref="player"
        key="file"
        class="plyr player-file"
        :data-poster="
          data.settings.poster && data.value.poster
            ? data.value.poster.sizes.l
            : null
        "
        playsinline
      >
        <source
          :src="src"
          type="video/mp4"
        >
      </video>

      <Richtext
        v-if="data.value.caption"
        :data="{ innerHTML: data.value.caption }"
      />
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import Richtext from '@/components/typo/richtext';

export default {
  name: 'VideoPlayer',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      plyr: null,
      src: null,
      hls: null,
      currentVideo: 'default',
      source: 'url',
    };
  },
  computed: {
    video() {
      let value = false;

      if (
        this.currentVideo === 'mobile'
        && this.data.settings.mobile_toggle
        && this.data.value.src_mobile
      ) {
        if (this.data.settings.mobile.source === 'url') {
          value = this.data.value.url_mobile;
        } else value = this.data.value.src_mobile.url;
      } else if (this.data.settings.source === 'url') {
        value = this.data.value.url;
      } else {
        value = this.data.value.src.url;
      }
      return value;
    },
    computedTheme() {
      let value = false;
      if (this.theme) {
        value = this.theme;
      } else if (this.data.settings.style) {
        value = this.data.settings.style;
      }
      return value;
    },
    full() {
      return this.computedTheme === 'full' || this.computedTheme === 'cover';
    },
    ratio() {
      let value = null;
      if (this.full) {
        value = this.$mq.isMobile ? '4:5' : '5:2';
      }
      return value;
    },
  },
  watch: {
    src(value) {
      if (value) {
        this.$nextTick(() => {
          const { player } = this.$refs;
          const { link } = value.files.find((video) => video.quality === 'hls');

          this.plyr = new Plyr(player, {
            autoplay: this.full,
            controls: ['play', 'progress', 'mute'],
            /* eslint-disable-next-line */
            iconUrl: require("@/assets/svg/icons/plyr.svg"),
            ratio: this.ratio,
            fullscreen: false,
            storage: {
              enabled: false,
            },
            loop: {
              active: this.full,
            },
            muted: true,
          });
          if (this.source === 'url') {
            if (Hls.isSupported()) {
              this.hls = new Hls({ startLevel: 5 });
              this.hls.loadSource(link);
              this.hls.attachMedia(player);

              this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                if (this.full) {
                  player.play();
                }
              });
            } else if (player.canPlayType('application/vnd.apple.mpegurl')) {
              player.src = link;
              player.addEventListener('loadedmetadata', () => {
                if (this.full) {
                  player.play();
                }
              });
            }
          }
        });
      }
    },
  },
  mounted() {
    this.currentSrc();
    this.$bus.$on('windowResized', this.currentSrc);
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.currentSrc);
  },
  methods: {
    currentSrc() {
      let src = this.video;

      // this.$nextTick(() => {
      if (
        this.$mq.isMobile
        && this.data.settings.mobile_toggle
        && this.data.value.src_mobile
      ) {
        this.currentVideo = 'mobile';

        if (this.data.settings.mobile.source === 'url') {
          this.source = 'url';
        } else this.source = 'file';
      } else {
        this.currentVideo = 'default';

        if (this.data.settings.source === 'url') {
          this.source = 'url';
        } else this.source = 'file';
      }

      src = this.video;
      this.src = src;
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.video {
  position: relative;
  width: 100%;

  &--default {
    @include mq(s) {
      grid-column: 2 / -1;
    }

    @include mq(m) {
      grid-column: 4 / span 8;
    }
  }

  &--full {
    @extend %full;

    /deep/ [data-plyr="play"],
    /deep/ .plyr__volume {
      display: none;
    }

    video {
      object-fit: cover;
    }
  }

  &--cover {
    grid-column: 1 / -1;
    @extend %full;

    video {
      object-fit: cover;
    }
  }
}
</style>

<style lang="scss">
.plyr {
  --plyr-color-main: $color-white;
  --plyr-control-icon-size: 21px;
  --plyr-range-track-height: 0px;
  --plyr-range-thumb-height: 0px;
  --plyr-range-thumb-shadow: none;

  .plyr__progress__buffer {
    border-radius: 0px;
  }

  .plyr__progress input[type="range"] {
    border-radius: 0px;
  }

  .plyr__tooltip {
    display: none;
  }

  .plyr__volume {
    width: auto;
    max-width: auto;
    min-width: auto;
  }

  .plyr__control {
    @media (hover: hover) {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
