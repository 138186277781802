<template>
  <div :class="['gallery', `gallery--${computedTheme}`]">
    <div
      ref="images"
      class="images"
    >
      <div
        v-for="(item, index) in data.value.gallery"
        :key="index"
        class="item"
      >
        <Figure
          :data="{
            value: {
              src: item,
            },
            settings: {},
          }"
          :theme="computedTheme ? `gallery--${computedTheme}` : 'gallery'"
          :lazyload="false"
          disable-ratio
        />
      </div>
    </div>
    <div class="bottom">
      <div
        v-if="caption && computedTheme !== 'work'"
        class="caption"
      >
        <Richtext
          class="placeholder"
          typo="caption"
          tag="figcaption"
          :value="longestCaption"
        />
        <Richtext
          typo="caption"
          tag="figcaption"
          :value="caption"
        />
      </div>
      <div class="controls">
        <Icon
          name="arrow-left"
          @click.native="previous"
        />
        <Icon
          name="arrow-right"
          @click.native="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';
import Icon from '@/components/ui/icon';
import Richtext from '@/components/typo/richtext';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
    Icon,
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        draggable: !!this.$mq.isTouchDevice,
        imagesLoaded: true,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true,
      },
    };
  },
  computed: {
    caption() {
      const value = false;
      const index = this.gallery ? this.gallery.selectedIndex : 0;
      const image = this.data.value.gallery[index];

      if (image.caption !== '') {
        return image.caption;
      }

      return value;
    },
    longestCaption() {
      let value = '';

      this.data.value.gallery.forEach((image) => {
        const { caption } = image;
        if (caption && caption.length > value.length) {
          value = caption;
        }
      });

      return value;
    },
    computedTheme() {
      return this.theme
        ? this.theme
        : this.data.settings.style !== 'default'
          ? this.data.settings.style
          : false;
    },
  },
  mounted() {
    this.setThemeSettings();
    this.gallery = new Flickity(this.$refs.images, this.settings);
    // this.gallery.on('change', () => {
    //   if (this.computedTheme === 'full' && !this.$mq.isMobile) {
    //     this.gallery.reloadCells();
    //   }
    // });
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
  methods: {
    previous() {
      this.gallery.previous(false, false);
    },

    next() {
      this.gallery.next(false, false);
    },
    setThemeSettings() {
      if (this.computedTheme === 'work') {
        this.settings.cellAlign = 'center';
        // this.settings.wrapAround = false;
      }
    },
  },
};
</script>

<style lang="scss">
  .gallery {
    display: grid;
    row-gap: var(--row-gap-s);

    &.block {
      @include mq(s) {
        grid-column: 2 / -1;
      }

      @include mq(m) {
        grid-column: 4 / span 8;
      }

      &.gallery {
        &--full {
          grid-column: 1 / -1;
        }
      }
    }

    .flickity {
      &-slider {
        display: flex;
        align-items: center;
      }
    }

    .item {
      margin-right: var(--spacer-s);
      width: 100%;

      @include mq(m) {
        max-height: var(--media-max-height)
      }
    }

    // &--full-old {
    //   grid-column: 1 / -1;
    //   @extend %full;

    //   .item {
    //     @include mq(m) {
    //       width: initial;

    //       &:not(.is-selected) {
    //         .figure {
    //           max-height: 400px;
    //         }
    //       }
    //     }
    //   }
    //   figcaption {
    //     margin-left: var(--spacer-s);
    //   }

    //   .controls {
    //     margin-right: var(--spacer-s);
    //   }
    // }

    &--full {
      grid-column: 1 / -1;
    }

    .bottom {
      display: grid;
      grid-auto-flow: column;

      .caption {
        display: grid;

        > * {
          grid-area: 1 / 1;
        }

        .placeholder {
          visibility: hidden;
        }
      }

      .controls {
        display: grid;
        grid-auto-flow: column;
        gap: var(--column-gap-s);
        justify-self: end;

        @include mq(m) {
          gap: calc(var(--column-gap-s) / 2);
        }
      }
    }

    &--work {
      .item {
        max-height: calc(var(--vh) * 70);
      }

      .bottom {
        .controls {
          bottom: 0;
          display: none;
          right: 0;
          position: absolute;

          @include mq(s) {
            display: grid;
          }
        }
      }
    }
  }
</style>
