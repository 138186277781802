/* eslint-disable no-undef */

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Page from '@/views/Page.vue';
import Archive from '@/views/Archive.vue';
import Single from '@/views/Single.vue';
import NotFound from '@/views/404.vue';
import Preview from '@/views/Preview.vue';

const singles = new Map([
  ['post', Single],
  ['work', Single],
  ['exhibition', Single],
  ['event', Single],
  ['digital_guide', Single],
]);

Vue.use(VueRouter);

const { show_on_front, page_on_front } = __VUE_WORDPRESS__.routing;

const { postTypes, i18n } = __VUE_WORDPRESS__.state;

const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

let langs = null;

if (i18n) {
  store.commit('ADD_LANGUAGES', Object.keys(i18n.langs));

  langs = Object.keys(i18n.langs).filter((lang) => lang !== i18n.default);
  langs = langs.join('|');
} else {
  store.commit('ADD_LANGUAGES', ['default']);
}

const exhibitionSub = {
  it: {
    current: { title: 'Mostre in corso', slug: 'in-corso' },
    upcoming: { title: 'Mostre future', slug: 'future' },
    past: { title: 'Mostre passate', slug: 'passate' },
  },
  en: {
    current: { title: 'Current exhibitions', slug: 'current' },
    upcoming: { title: 'Upcoming exhibitions', slug: 'upcoming' },
    past: { title: 'Past exhibitions', slug: 'past' },
  },
};

const eventSub = {
  it: {
    scheduled: { title: 'Eventi in programma', slug: 'in-programma' },
    archive: { title: 'Eventi passati', slug: 'archivio' },
  },
  en: {
    scheduled: { title: 'Scheduled events', slug: 'scheduled' },
    archive: { title: 'Past events', slug: 'archive' },
  },
};

const baseRoutes = [
  {
    path: langs ? `/:lang(${langs})?/404` : '/404',
    name: '404',
    component: NotFound,
    meta: {
      hideFooter: true,
    },
  },
  {
    path: langs ? `/:lang(${langs})?/` : '/',
    name: 'Home',
    component: Page,
    meta: {
      slug: page_on_front,
      type: show_on_front ? 'pages' : 'posts',
    },
  },
  { path: `/${page_on_front}`, redirect: '/' },
  {
    path: langs ? `/:lang(${langs})?/preview/:type/:id` : '/preview/:type/:id',
    name: 'Preview',
    component: Preview,
  },
];

const routes = baseRoutes;

Object.values(postTypes).forEach((type) => {
  if (type.has_archive) {
    const archiveSlugs = Object.values(type.slugs);
    archiveSlugs.forEach((archiveSlug) => {
      routes.push({
        path: langs ? `/:lang(${langs})?/${archiveSlug}` : `/${archiveSlug}`,
        name: `${archiveSlug.charAt(0).toUpperCase()}${archiveSlug.slice(1)}`,
        component: type.name === 'work' ? Page : Archive,
        meta: {
          archive: type.name !== 'work',
          typeName: type.name,
          typeBase: type.rest_base,
          slug: archiveSlug,
          pageTitle: `${archiveSlug.charAt(0).toUpperCase()}${archiveSlug.slice(
            1,
          )}`,
        },
      });
    });
  }

  if (type.name === 'exhibition') {
    const archiveSlugs = type.slugs;
    Object.keys(exhibitionSub).forEach((lang) => {
      Object.keys(exhibitionSub[lang]).forEach((key) => {
        routes.push({
          path: langs
            ? `/:lang(${langs})?/${archiveSlugs[lang]}/${exhibitionSub[lang][key].slug}`
            : `/${archiveSlugs[lang]}/${exhibitionSub[lang][key].slug}`,
          name: `${key.charAt(0).toUpperCase()}${key.slice(1)}${
            type.label
          }-${lang}`,
          component: Archive,
          meta: {
            archive: true,
            agenda: key,
            typeName: type.name,
            typeBase: type.rest_base,
            slug: archiveSlugs[lang],
            pageTitle: exhibitionSub[lang][key].title,
          },
        });
      });
    });
  }

  if (type.name === 'event') {
    const archiveSlugs = type.slugs;
    Object.keys(eventSub).forEach((lang) => {
      Object.keys(eventSub[lang]).forEach((key) => {
        routes.push({
          path: langs
            ? `/:lang(${langs})?/${archiveSlugs[lang]}/${eventSub[lang][key].slug}`
            : `/${archiveSlugs[lang]}/${eventSub[lang][key].slug}`,
          name: `${key.charAt(0).toUpperCase()}${key.slice(1)}${
            type.label
          }-${lang}`,
          component: Archive,
          meta: {
            archive: true,
            agenda: key,
            typeName: type.name,
            typeBase: type.rest_base,
            slug: archiveSlugs[lang],
            pageTitle: eventSub[lang][key].title,
          },
        });
      });
    });
  }
});

Object.keys(postTypes).forEach((key) => {
  const archiveSlugs = Object.values(postTypes[key].slugs);
  archiveSlugs.forEach((archiveSlug) => {
    if (key !== 'page') {
      routes.push({
        path: langs
          ? `/:lang(${langs})?/${archiveSlug}/:slug`
          : `/${archiveSlug}/:slug`,
        name: postTypes[key].labels.singular_name,
        ...(key === 'digital_guide' ? { component: singles.get(key) } : {
          components: {
            default: Page,
            single: singles.get(key),
          },
        }),
        props: {
          default: {
            slug:
              postTypes[key].name === 'exhibition'
              || postTypes[key].name === 'event'
                ? page_on_front
                : archiveSlug,
            type: 'pages',
          },
        },
        meta: {
          hasArchive:
            postTypes[key].has_archive && postTypes[key].name !== 'work',
          single: key !== 'digital_guide',
          type: postTypes[key].rest_base,
          typeName: postTypes[key].name,
          hideHeader: key === 'digital_guide',
          hideFooter: key === 'digital_guide',
        },
      });
    }
  });
});

routes.push(
  {
    path: langs ? `/:lang(${langs})?/:slug` : '/:slug',
    name: 'Page',
    component: Page,
  },
  {
    path: langs ? `/:lang(${langs})?/:parent/:slug` : '/:parent/:slug',
    name: 'SubPage',
    component: Page,
  },
  {
    path: langs
      ? `/:lang(${langs})?/:parent/:child/:slug`
      : '/:parent/:child/:slug',
    name: 'SubSubPage',
    component: Page,
  },
);

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (from.meta.single) {
      return false;
    }
    if (from.path !== to.path) {
      return { x: 0, y: 0 };
    }
    return false;
  },
});

router.beforeEach(async (to, from, next) => {
  store.commit('SET_LOADING', true);

  if (from.path !== to.path) {
    store.commit('SET_CURRENT_POST', null);
  }

  if (
    to.meta.customView
    || to.name === '404'
    || to.name === 'Search'
    || to.name === 'Preview'
  ) {
    document.title = `${to.name ? `${to.name} — ` : ''}${siteTitle}`;
    if (!document.body.classList.contains('app-loaded')) {
      document.body.classList.add('app-loaded');
    }
    store.commit('SET_LOADING', false);
    next();
    return;
  }

  if (store.state.menuIsOpen) {
    store.commit('SET_MENU', false);
  }
  if (store.state.datePicker) {
    store.commit('TOGGLE_DATE_PICKER');
  }

  const navItems = [
    ...store.getters.menu('navigation-1').items,
    ...store.getters.menu('navigation-2').items,
  ];

  const currentItem = navItems.find(
    (item) => Vue.prototype.$relativeUrl(item.url) === to.path,
  );

  if (currentItem && currentItem.children.length > 0) {
    router.push(Vue.prototype.$relativeUrl(currentItem.children[0].url));
  }

  const slug = to.meta.slug ? to.meta.slug : to.params.slug;
  const lang = to.params.lang
    ? to.params.lang
    : i18n
      ? i18n.default
      : 'default';

  store.commit('SET_LANG', lang);

  const request = {
    type: to.meta.type || 'pages',
    slug,
    lang,
  };

  const promises = [];

  if (to.meta.archive) {
    const params = {
      exclude: null,
      lang,
      offset: 0,
      order: 'asc',
      orderby: undefined,
      per_page: 12,
    };

    if (to.meta.typeName === 'exhibition') {
      if (!to.meta.agenda) {
        router.push({
          name: `CurrentExhibitions-${lang}`,
          params: { lang: lang !== i18n.default ? lang : undefined },
        });
      } else {
        params.agenda = to.meta.agenda;
      }

      promises.push(
        store.dispatch('getSingleBySlug', {
          type: 'pages',
          slug,
          lang,
        }),
      );
    }

    if (to.meta.typeName === 'event') {
      if (!to.meta.agenda) {
        router.push({
          name: `ScheduledEvents-${lang}`,
          params: { lang: lang !== i18n.default ? lang : undefined },
        });
      } else {
        params.agenda = to.meta.agenda;
      }

      promises.push(
        store.dispatch('getSingleBySlug', {
          type: 'pages',
          slug,
          lang,
        }),
      );
      promises.push(
        store.dispatch('getItems', {
          type: 'event_category',
          params: {
            per_page: 100,
            lang,
          },
        }),
      );
    }

    promises.push(
      store.dispatch('getItems', { type: to.meta.typeBase, params }),
    );
  } else {
    promises.push(store.dispatch('getSingleBySlug', request));
  }

  if (to.meta.single && from.name && !from.meta.single) {
    store.dispatch('lockView', { view: '.page-container' });
  }

  if (to.meta.single) {
    if (!from.name) {
      if (to.meta.hasArchive && to.meta.typeName !== 'exhibition') {
        to.matched[0].components.default = Archive;
      }

      const defaultRequest = {
        type: to.matched[0].props.default.type,
        slug: to.matched[0].props.default.slug,
        lang,
      };

      promises.push(store.dispatch('getSingleBySlug', defaultRequest));
    } else if (from.name && from.meta.archive) {
      to.matched[0].components.default = Archive;
    }
  }

  Promise.all(promises).then((res) => {
    if (!document.body.classList.contains('app-loaded')) {
      document.body.classList.add('app-loaded');
    }

    const page = res[0];

    if (page && slug) {
      // Redirect if hidden product
      if (page.catalog_visibility && page.catalog_visibility === 'hidden') {
        router.push({ name: '404' });
      }
      if (to.path === '/') {
        document.title = `${siteTitle}${
          description ? ` — ${description}` : ''
        }`;
      } else {
        // Avoid HTML entities in title
        const p = document.createElement('p');
        p.innerHTML = to.meta.archive
          ? to.meta.pageTitle
          : page.title
            ? page.title.rendered
            : page.name;
        document.title = `${
          p.innerText ? `${p.innerText} — ` : ''
        }${siteTitle}`;
      }

      if (i18n) {
        const { default_locale } = i18n.langs[lang];
        document.documentElement.lang = default_locale.replace('_', '-');
      }

      store.commit('SET_LOADING', false);
      next();
    } else {
      router.push({
        name: '404',
        params: { lang: lang !== i18n.default ? lang : undefined },
      });
    }
  });
});

export default router;
