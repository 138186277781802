const labels = {
  it: {
    archive_filters: 'Filtra',
    archive_filters_all: 'Tutti',
    back_cta: 'Indietro',
    back_home_cta: 'Torna alla home',
    back_parent_cta: 'Torna a',
    close_cta: 'Chiudi',
    credits: 'website by Giga Design Studio',
    curation_label: 'A cura di',
    date_picker_cta: 'Seleziona data',
    duration_label: 'Data',
    empty_event_archive_message: 'Nessun evento per i filtri selezionati.',
    empty_exhibition_archive_message:
      'Nessuna mostra per i filtri selezionati.',
    events_archive_title: 'Calendario eventi',
    form_address: 'Indirizzo',
    form_city: 'Città',
    form_contact:
      'Voglio entrare in contatto con l’ufficio stampa per avere un accredito',
    form_country: 'Nazione',
    form_error: 'Campo errato',
    form_error_message: 'C\'è stato un errore nell\'invio.',
    form_free: 'Sono in Pinacoteca e voglio accreditarmi',
    form_name: 'Nome',
    form_newsletter: 'Voglio ricevere la newsletter',
    form_newspaper: 'Testata',
    form_other: 'Altro',
    form_personal_email: 'Email personale',
    form_phone: 'Recapito telefonico',
    form_postcode: 'CAP',
    form_press_subject: 'Accredito stampa',
    form_professional_email: 'Email aziendale',
    form_professional: 'Professionista',
    form_publicist: 'Pubblicista',
    form_register: 'Ordine dei giornalisti di',
    form_role: 'Ruolo',
    form_submit: 'Invia',
    form_success_message: 'Richiesta inviata.',
    form_subject: 'Richiesta',
    form_surname: 'Cognome',
    information_label: 'Informazioni',
    menu_cta: 'Menu',
    newsletter_cta: 'Invia',
    newsletter_already_message: 'Email già registrata.',
    newsletter_error_message: 'Si è verificato un errore.',
    newsletter_success_message: 'Iscrizione avvenuta!',
    not_found_message: '404 pagina non trovata',
    reset_cta: 'Cancella',
    space_label: 'Spazio',
    today: 'Oggi',
    play: 'Ascolta',
    pause: 'Pausa',
  },
  en: {
    archive_filters: 'Filter',
    archive_filters_all: 'All',
    back_cta: 'Back',
    back_home_cta: 'Back to home',
    back_parent_cta: 'Go back to',
    close_cta: 'Close',
    credits: 'website by Giga Design Studio',
    curation_label: 'Curated by',
    date_picker_cta: 'Select date',
    duration_label: 'Date',
    empty_event_archive_message: 'No events with the selected filters.',
    empty_exhibition_archive_message:
      'No exhibitions with the selected filters.',
    events_archive_title: 'Events calendar',
    form_address: 'Address',
    form_city: 'City',
    form_contact:
      'I am a journalist and I would like to receive complimentary access only.',
    form_country: 'Nation',
    form_error: 'Wrong field',
    form_error_message: 'Failed to send enquiry.',
    form_free:
      'I am planning an article on Pinacoteca Agnelli and would like to receive press information and complimentary access. ',
    form_name: 'Name',
    form_newsletter: 'I would like to receive the newsletter',
    form_newspaper: 'News media',
    form_other: 'Other',
    form_personal_email: 'Personal email',
    form_phone: 'Phone number',
    form_postcode: 'Postcode',
    form_press_subject: 'Press accreditation',
    form_professional_email: 'Professional email',
    form_professional: 'Professional',
    form_publicist: 'Publicist',
    form_register: 'Journalist order',
    form_role: 'Role',
    form_submit: 'Send',
    form_success_message: 'Enquiry submitted.',
    form_subject: 'Enquiry',
    form_surname: 'Surname',
    information_label: 'Information',
    menu_cta: 'Menu',
    newsletter_cta: 'Send',
    newsletter_already_message: 'Email already registered.',
    newsletter_error_message: 'There was an error.',
    newsletter_success_message: 'Subscribed!',
    not_found_message: '404 page not found',
    reset_cta: 'Reset',
    space_label: 'Space',
    today: 'Today',
    play: 'Play',
    pause: 'Pause',
  },
};

labels.install = (vm, store) => {
  vm.prototype.$labels = () => labels[store.state.lang];
};

export default labels;
