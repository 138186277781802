<template>
  <div class="guides">
    <div
      v-if="post.acf.link"
      class="link-wrapper"
    >
      <Link
        :data="{value:{ link: post.acf.link}}"
        theme="full-width"
      />
    </div>
    <Gutenberg
      :data="post.gds_blocks"
    />
    <div
      v-if="hasTextGuide"
      class="accessibility-controls"
    >
      <div>
        <div
          class="controls"
        >
          <Icon
            name="minus"
            theme="l white"
            :disabled="$store.state.zoom === 1"
            @click.native="zoomOut"
          />
          <Icon
            name="plus"
            theme="l white"
            :disabled="$store.state.zoom === maxZoom"
            @click.native="zoomIn"
          />
        </div>
      </div>
      <div class="controls">
        <Link
          :data="{
            url: '',
            title: 'IT',
          }"
          :class="{inactive: $route.params.lang}"
          @click.native="$router.push({params: {lang : undefined}})"
        />
        <Link
          :data="{
            url: '',
            title: 'En',
          }"
          :class="{inactive: $route.params.lang !== 'en'}"
          @click.native="$router.push({params: {lang : 'en'}})"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Gutenberg from '@/components/blocks';
import Icon from '@/components/ui/icon';
import Link from '@/components/typo/link';

export default {
  name: 'Guide',
  components: {
    Gutenberg,
    Icon,
    Link,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maxZoom: 1.5,
      zoomIncrement: 0.1,
      hasTextGuide: this.post.gds_blocks?.find((block) => block.attrs.data.value.type === 'text'),
    };
  },
  computed: {
    isItalian() {
      return this.$store.state.lang === 'it' || this.$store.state.lang === 'default';
    },
  },
  methods: {
    zoomIn() {
      this.$store.state.zoom = Math.min(this.maxZoom, this.$store.state.zoom += this.zoomIncrement);
    },
    zoomOut() {
      this.$store.state.zoom = Math.max(1, this.$store.state.zoom -= this.zoomIncrement);
    },
  },
};

</script>

<style lang="scss">
.single.guides {
  min-height: 100svh;
  display: flex !important;
  flex-direction: column;

  .gutenberg {
    flex: 1;
  }
}

.link-wrapper {
  grid-column: -1 / 1;
  padding: var(--spacer-m);
}

.accessibility-controls {
  display: flex;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  padding: var(--column-gap-s);
  background-color: var(--black);
  width: 100%;
  grid-column: 1 / -1;
  flex: 1;

  .controls {
    display: flex;
    gap: var(--column-gap-s);
  }
}

.inactive {
  opacity: 0.3;
}
</style>
