<template>
  <component
    :is="tag"
    :class="['richtext', `typo--${typo}`, theme ? `richtext--${theme}` : false]"
    v-html="value || (data ? data.innerHTML : '')"
  />
</template>

<script>
export default {
  name: 'RichText',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: () => {},
    },
    tag: {
      type: String,
      default: 'div',
    },
    typo: {
      type: String,
      default: 'p',
    },
    theme: {
      type: [Boolean, String],
      default: false,
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    if (window.twttr && this.mergeProps.value.includes('twitter-tweet')) {
      // eslint-disable-next-line no-undef
      window.twttr.widgets.load();
    }

    // // eslint-disable-next-line no-undef
    // FB.init({
    //   xfbml: true,
    //   version: 'v10.0',
    // });
  },
};
</script>

<style lang="scss">
  .richtext {
    overflow-wrap: break-word;
    max-width: 650px;

    em, i {
      font-style: italic;
    }

    a {
      text-decoration: underline;
    }

    ul,
    ol {
      padding-left: 1.2em;
    }

    b,
    strong {
      font-weight: inherit;
    }

    &.block {
      @include mq(s) {
        grid-column: 2 / span 6;
      }

      @include mq(m) {
        grid-column: 4 / span 6;
      }
    }

    &.block,
    &--indent {
      --indent: 20px;
      text-indent: var(--indent);

      @include mq(m) {
        --indent: 40px;
      }
    }

    &--italic {
      font-style: italic;
    }

    &.typo--p {
      b,
      strong {
        font-family: "Univers";
        font-size: 18px;
      }
    }

    &.typo--4 {
      max-width: 700px;
      grid-column: 1 / -1;

      @include mq(s) {
        grid-column: 2 / -1;
      }

      @include mq(m) {
        grid-column: 4 / span 8;
        max-width: 1000px;
      }
    }
  }
</style>
