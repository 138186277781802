var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'table',
    _vm.data.settings.gap ? "table--gap" : false,
    _vm.columns !== 1 ? "table--columns" : false ]},_vm._l((_vm.columns),function(column,columnIndex){return _c('div',{key:columnIndex,staticClass:"column"},[_c('div',{staticClass:"rows"},_vm._l((_vm.columns !== 1
          ? column.rows
          : _vm.data.value.rows),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"row"},[_c('header',{staticClass:"typo--6"},[_c('div',{staticClass:"typo--6--trimmed"},[_vm._v(" "+_vm._s(row.key)+" ")]),(row.value && row.value !== '')?_c('div',{staticClass:"dots",style:({
              borderImageSource: ("url(" + (require(("@/assets/svg/dot-" + (_vm.data.settings.color ? _vm.data.settings.color : 'black') + ".svg"))) + ")"),
            })}):_vm._e(),_c('div',{staticClass:"value typo--6--trimmed"},[_vm._v(" "+_vm._s(row.value)+" ")])]),(row.description !== '')?_c('Richtext',{attrs:{"value":row.description}}):_vm._e()],1)}),0),(_vm.data.value.notes !== '' && _vm.columns === 1)?_c('Richtext',{attrs:{"value":_vm.data.value.notes,"typo":"caption"}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }