export default {
  currentPost: (state) => state.currentPost,
  defaultLang: (state) => state.wp.i18n.default,
  lang: (state) => state.lang,
  langs: (state) => state.wp.i18n.langs,
  menuIsOpen: (state) => state.menuIsOpen,
  loading: (state) => state.loading,
  overlay: (state) => state.overlay,
  snackbar: (state) => state.snackbar,
  transition: (state) => state.transition,
  fontsLoaded: (state) => state.fontsLoaded,
  pinnedExhibition: (state) => state.pinnedExhibition,
  top(state) {
    return `-${state.scrollY}px`;
  },
};
