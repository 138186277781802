<template>
  <div class="typo--caption">
    <Link
      v-for="item in items"
      :key="item.id"
      :data="{
        url: item.url,
        title: item.content,
        target: item.target,
        classes: item.css
      }"
      theme="inline"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Link from '@/components/typo/link';

export default {
  components: {
    Link,
  },
  props: {
    value: {
      type: [Boolean, String],
      default: false,
    },
  },
  computed: {
    ...mapGetters(['menu']),
    items() {
      let menu = this.value;

      if (this.value) {
        menu = this.menu(this.value);
      }

      return menu ? menu.items : null;
    },
  },
};
</script>

<style></style>
