var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filters"},[_c('Link',{attrs:{"data":{
      fn: function () {
        _vm.setFilter(null);
      },
      title: _vm.$labels().archive_filters_all,
    },"theme":!_vm.filter ? 'link--straight' : 'link--sub'}}),_vm._l((_vm.eventCategories),function(cat){return _c('Link',{directives:[{name:"show",rawName:"v-show",value:(cat.count !== 0),expression:"cat.count !== 0"}],key:cat.id,attrs:{"data":{
      fn: function () {
        _vm.setFilter(cat);
      },
      title: cat.name,
    },"theme":_vm.filter === cat.name ? 'link--straight' : 'link--sub'}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }