<template>
  <div class="language-selector">
    <div
      v-for="item in orderedLanguages"
      :key="item.code"
      :data-selected="item.code === lang"
      :data-value="item.code"
      class="typo--6--trimmed"
      @click="changeLanguage"
    >
      {{ item.code }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LanguageSelector',
  data() {
    return {
      value: '',
    };
  },
  computed: {
    ...mapGetters(['lang', 'defaultLang', 'langs', 'currentPost']),
    orderedLanguages() {
      const languages = Object.values(this.langs);
      const defaultLanguage = languages.find(
        (language) => language.code === this.defaultLang,
      );

      languages.splice(languages.indexOf(defaultLanguage), 1);
      languages.splice(0, 0, defaultLanguage);

      return languages;
    },
  },
  created() {
    this.value = this.lang;
  },
  methods: {
    checkLanguage(val) {
      return this.currentPost.wpml_translations.find(
        (translation) => translation.locale === val,
      );
    },
    changeLanguage(e) {
      if (e.target.dataset.value === this.value) return;
      this.value = e.target.dataset.value;

      let path = this.value === this.defaultLang ? '/' : `/${this.value}`;
      if (
        this.currentPost
          && this.currentPost
          && this.currentPost.wpml_translations
      ) {
        const currentTranslation = this.checkLanguage(this.value);
        if (currentTranslation) {
          path = this.$relativeUrl(currentTranslation.href);
        }
      }

      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
  .language-selector {
    column-gap: var(--spacer-m);
    display: grid;
    grid-auto-flow: column;
    width: max-content;

    div {
      cursor: pointer;
      opacity: 0.3;
      transition: opacity 0.05s ease;

      @media (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }
    }

    div[data-selected="true"] {
      opacity: 1;
    }

    @include mq(m) {
      column-gap: var(--spacer-s);
    }
  }
</style>
