<template>
  <div
    :style="{
      background: background ? `var(--${background})` : false,
      color: color,
      fill: color,
      paddingBottom: paddingBottom,
    }"
    :class="[
      'gutenberg',
      'group',
      'full',
      background ? `group--${background}` : false,
    ]"
  >
    <component
      :is="components.get(parseBlockName(block.blockName))"
      v-for="(block, index) in data.innerBlocks"
      :key="index"
      :class="[
        'block',
        spacing(block),
        `block--${index}`,
        background ? `block--${background}` : false,
      ]"
      :data="blockData(block)"
      :style="
        index === 0 && data.position === 0 && $mq.isMobile
          ? { paddingTop: '116px' }
          : false
      "
    />
  </div>
</template>

<script>
import { parseBlockName } from '@/assets/js/utils';
import components from '@/assets/js/components';

export default {
  name: 'Group',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parseBlockName,
      components,
      colors: new Map([
        ['red', 'black'],
        ['black', 'white'],
        ['light-black', 'white'],
        ['white', 'black'],
      ]),
    };
  },
  computed: {
    background() {
      let value = false;
      const { attrs } = this.data;

      if (attrs.length !== 0) {
        value = attrs.backgroundColor;
      }

      return value;
    },
    color() {
      return this.background ? this.colors.get(this.background) : 'black';
    },
    paddingBottom() {
      let value = 'var(--spacer-m)';

      const noSpaceBlocks = ['archive', 'newsletter', 'posts', 'video'];
      const block = this.data.innerBlocks.at(-1);
      const blockName = block.blockName.split('/').at(-1);

      if (noSpaceBlocks.includes(blockName)) {
        value = 0;
      }

      if (blockName === 'image') {
        const { data } = block.attrs;
        const { style } = data.settings;
        const caption = data.settings.caption && data.value.caption !== '';

        if (style === 'full' && !caption) {
          value = 0;
        }
      }

      if (blockName === 'link') {
        const { data } = block.attrs;
        const { theme } = data.settings;

        if (theme === 'huge') {
          value = 0;
        }
      }

      return value;
    },
  },
  methods: {
    spacing(block) {
      let value = false;
      const acf = block.blockName.includes('acf/');

      if (acf) {
        const { spacing } = block.attrs.data.settings;

        if (spacing && spacing !== 'default') {
          value = `block--${spacing}`;
        }
      }

      return value;
    },
    blockData(block) {
      let value = block;

      if (block.blockName.includes('acf/')) {
        value = block.attrs.data;
      }

      value = {
        ...value,
        settings: {
          ...value.settings,
          groupBackground: this.background,
          color: this.color,
        },
      };

      return value;
    },
  },
};
</script>

<style lang="scss">
  .group {
    &--red {
      ::selection {
        background-color: var(--white);
      }
    }
  }
</style>
