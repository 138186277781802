export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  SET_MENU(state, value) {
    state.menuIsOpen = value;
  },
  SET_OVERLAY_POSITION(state, value) {
    state.overlayPosition = value;
  },
  SET_FONTS(state, value) {
    state.fontsLoaded = value;
  },
  SET_FILTER(state, value) {
    if (value === state.filter) {
      state.filter = null;
    } else state.filter = value;
  },
  SET_EVENTS_DATE(state, value) {
    state.eventsDate = value;
  },
  TOGGLE_DATE_PICKER(state) {
    state.datePicker = !state.datePicker;
  },
  SET_PINNED_EXHIBITION(state, value) {
    state.pinnedExhibition = value;
  },
  LOCK_VIEW(state, { view, getters }) {
    const viewElement = document.querySelector(view);
    state.scrollY = window.scrollY;
    const style = {
      position: 'fixed',
      width: '100%',
      top: getters.top,
      pointerEvents: 'none',
    };

    Object.assign(viewElement.style, style);
    state.overlay = true;
  },
  UNLOCK_VIEW(state, { view }) {
    const viewElement = document.querySelector(view);

    viewElement.removeAttribute('style');
    window.scrollTo(0, state.scrollY);
    state.overlay = false;
  },
};
