<template>
  <inline-svg
    class="map"
    :src="require(`@/assets/svg/map.svg`)"
  />
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .map {

    @include mq(s) {
      grid-column: 2 / span 6;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }
</style>
