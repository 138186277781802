var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['menu', { 'menu--expanded': _vm.expanded }]},[(_vm.$mq.isMobile && _vm.parent)?_c('div',{staticClass:"typo--5--trimmed parent",on:{"click":_vm.toggleBar}},[_vm._v(" "+_vm._s(_vm.parent)+" ")]):_vm._e(),_c('Icon',{attrs:{"name":"arrow-down","size":"s","theme":"minimal"}}),(_vm.items.length)?_c('div',{staticClass:"menu-items",on:{"click":_vm.toggleBar}},_vm._l((_vm.items),function(item){return _c('Link',{directives:[{name:"show",rawName:"v-show",value:(!item.hide),expression:"!item.hide"}],key:item.id,ref:"link",refInFor:true,attrs:{"data":{
        url: item.url,
        title: item.content,
        target: item.target,
      },"theme":"link--sub"}})}),1):_vm._e(),(_vm.$route.meta.typeName === 'event')?_c('Filters',{staticClass:"menu-items",nativeOn:{"click":function($event){return _vm.toggleBar.apply(null, arguments)}}}):_vm._e(),(_vm.$route.meta.typeName === 'event')?_c('Link',{staticClass:"date-picker-toggle",attrs:{"icon":{
      name: 'arrow-down',
      position: 'after',
      rotation: _vm.datePicker ? 180 : 0,
    },"theme":"icon","data":{ fn: _vm.TOGGLE_DATE_PICKER, title: _vm.$labels().date_picker_cta }}}):_vm._e(),_c('DatePicker',{attrs:{"full-width":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }